import { ReactNode } from "react";
import {
  PortletItemContentWrapper,
  PortletItemHeaderWrapper,
  PortletItemTitle,
  PortletItemWrapper,
} from "../../styles/Portlet/portletStyle";
import { PortletStatus } from "../../../../types/DashBoard/Portlet";
import { useDeviceType } from "../../../../hooks/useDeviceType";

type PortletItemProps = {
  info: PortletStatus;
  children: ReactNode;
};

const PortletItem = ({ info, children }: PortletItemProps) => {
  const deviceType = useDeviceType();

  return (
    <PortletItemWrapper
      item
      draggable
      key={`portlet${info.id}`}
      sx={{
        flex: `0 1 ${
          info.width === "100%" ? info.width : `calc(${info.width} - 4px)`
        }`,
      }}
    >
      <PortletItemHeaderWrapper deviceType={deviceType}>
        <PortletItemTitle>{info.title}</PortletItemTitle>
      </PortletItemHeaderWrapper>
      <PortletItemContentWrapper>{children}</PortletItemContentWrapper>
    </PortletItemWrapper>
  );
};

export default PortletItem;
