import { lazy, Suspense, useEffect } from "react";
import { useRecoilState } from "recoil";
import PortletItem from "./PortletItem";
import { PortletListWrapper } from "../../styles/Portlet/portletStyle";
import {
  initialPortletStatus,
  portletStatusStore,
} from "../../../../stores/DashBoard/portletStore";
import { useDeviceType } from "../../../../hooks/useDeviceType";

const AchievementRateList = lazy(() => import("./AchievementRateList"));
const ForecastList = lazy(() => import("./ForecastList"));
const TodoStatusList = lazy(() => import("./TodoStatusList"));
const TodoTable = lazy(() => import("../Table/TodoTable"));

const SwitchPortletContent = ({ id }: { id: number }) => {
  switch (id) {
    case 1:
      return <AchievementRateList />;
    case 2:
      return <ForecastList />;
    case 3:
      return <TodoStatusList />;
    case 4:
      return <TodoTable />;
    default:
      return null;
  }
};

const PortletList = () => {
  const [portletList, setPortletList] = useRecoilState(portletStatusStore);
  const deviceType = useDeviceType();

  useEffect(() => {
    let result = [];
    if (deviceType === "pc") {
      result = initialPortletStatus;
    } else {
      result = portletList.map((el) => ({ ...el, width: "100%" }));
    }
    setPortletList(result);
  }, [deviceType]);

  return (
    <PortletListWrapper container>
      {portletList.map((portlet, idx) => {
        return (
          <PortletItem key={`portlet${portlet.id}`} info={portlet}>
            <Suspense fallback={<div>Loading...</div>}>
              <SwitchPortletContent id={portlet.id} />
            </Suspense>
          </PortletItem>
        );
      })}
    </PortletListWrapper>
  );
};

export default PortletList;
