import { Grid } from "@mui/material";
import {
  GRAYPALECONTENTS,
  GRAYPALETITLE,
  REDTITLE,
} from "../../../../../styles/Color";
import { CustomText } from "../../../../../styles/CustomText";
import {
  EditDropDownListComponentBox,
  EditInputSmallBox,
  LeadTableCardBox,
  LeadTableContent,
  LeadTableContentsBox,
  LeadTableTitle,
  TableFixedHeight,
} from "../../../../../styles/theme";
import { SalesActivityInfo } from "../../../../../system/types/SalesActivity";
import { isoDateFormatter } from "../../../../../components/Common/isoDateFormatter";
import {
  SALESACTIVITY_PRIORITY,
  SALESACTIVITY_TYPE,
} from "../../../../../system/constants";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

interface ActivityCreateProps {
  salesActivityData: SalesActivityInfo;
  setSalesActivityData: (salesActivityData: SalesActivityInfo) => void;
  activityModalState: string;
}

function ActivityCreate({
  salesActivityData,
  setSalesActivityData,
  activityModalState,
}: ActivityCreateProps) {
  const onChange = (args: any, type: string) => {
    if (type === "input") {
      setSalesActivityData({
        ...salesActivityData,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdown") {
      setSalesActivityData({
        ...salesActivityData,
        [(args.target as HTMLInputElement).name]: args.target.value,
      });
    } else if (type === "dropdownList") {
      setSalesActivityData({
        ...salesActivityData,
        [(args.target as HTMLInputElement).name]: args.target.itemData.id,
      });
    } else if (type === "date") {
      setSalesActivityData({
        ...salesActivityData,
        [(args.target as HTMLInputElement).name]: isoDateFormatter(
          new Date(args.target.value)
        ),
      });
    }
  };
  const fields: Object = {
    value: "id",
    text: "name",
  };
  return (
    <LeadTableCardBox mt="20px">
      <Grid container item xs={12} alignItems="center" padding="10px">
        <CustomText type="subtitle">작업 이력</CustomText>
        <CustomText fontSize="small" color={REDTITLE}>
          *
        </CustomText>
      </Grid>

      <LeadTableContentsBox container item xs={12} alignItems="center">
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              lineSpace
              color={GRAYPALECONTENTS}
            >
              제목
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent item xs={8} pl="20px" pr="10px">
            {activityModalState === "detail" ? (
              <CustomText
                type="subtitle"
                bold400
                lineSpace
                color={GRAYPALETITLE}
              >
                {salesActivityData?.title ? salesActivityData?.title : "-"}
              </CustomText>
            ) : (
              <EditInputSmallBox
                type="text"
                name="title"
                onChange={(e: any) => {
                  onChange(e, "input");
                }}
                value={salesActivityData.title || ""}
                placeholder="제목을 작성해 주세요."
                autoComplete="new-password"
              />
            )}
          </LeadTableContent>
        </TableFixedHeight>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              color={GRAYPALECONTENTS}
              lineSpace
            >
              작업일
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent item xs={8} pl="20px" pr="10px">
            {activityModalState === "detail" ? (
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALETITLE}
                lineSpace
              >
                {salesActivityData?.activityDate
                  ? salesActivityData?.activityDate
                  : "-"}
              </CustomText>
            ) : (
              <DatePickerComponent
                name="activityDate"
                format="yyyy-MM-dd"
                showClearButton={false}
                locale="ko"
                value={
                  salesActivityData?.activityDate
                    ? new Date(salesActivityData?.activityDate)
                    : undefined
                }
                onChange={(e: any) => {
                  onChange(e, "date");
                }}
                placeholder="작업일을 선택해주세요."
              />
            )}
          </LeadTableContent>
        </TableFixedHeight>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              color={GRAYPALECONTENTS}
              lineSpace
            >
              유형
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent item xs={8} pl="20px" pr="10px">
            {activityModalState === "detail" ? (
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALETITLE}
                lineSpace
              >
                {salesActivityData?.type ? salesActivityData?.type : "-"}
              </CustomText>
            ) : (
              <EditDropDownListComponentBox
                locale="ko"
                showClearButton={true}
                dataSource={SALESACTIVITY_TYPE}
                name="type"
                value={salesActivityData.type}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={"유형을 선택해주세요."}
                autoComplete="new-password"
              />
            )}
          </LeadTableContent>
        </TableFixedHeight>
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              color={GRAYPALECONTENTS}
              lineSpace
            >
              후속 조치 일자
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent item xs={8} pl="20px" pr="10px">
            {activityModalState === "detail" ? (
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALETITLE}
                lineSpace
              >
                {salesActivityData?.followUpDate
                  ? salesActivityData?.followUpDate
                  : "-"}
              </CustomText>
            ) : (
              <DatePickerComponent
                name="followUpDate"
                format="yyyy-MM-dd"
                showClearButton={false}
                min={
                  salesActivityData.activityDate
                    ? new Date(salesActivityData.activityDate)
                    : undefined
                }
                locale="ko"
                value={
                  salesActivityData?.followUpDate
                    ? new Date(salesActivityData?.followUpDate)
                    : undefined
                }
                onChange={(e: any) => {
                  onChange(e, "date");
                }}
                placeholder="작업일을 선택해주세요."
              />
            )}
          </LeadTableContent>
        </TableFixedHeight>

        {/* <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              color={GRAYPALECONTENTS}
              lineSpace
            >
              작업상태
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent item xs={8} pl="20px" pr="10px">
            {activityModalState === "detail" ? (
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALETITLE}
                lineSpace
              >
                {salesActivityData?.status ? salesActivityData?.status : "-"}
              </CustomText>
            ) : (
              <EditDropDownListComponentBox
                locale="ko"
                showClearButton={true}
                dataSource={SALESACTIVITY_STATUS}
                name="status"
                value={salesActivityData.status}
                onChange={(e: any) => {
                  onChange(e, "dropdown");
                }}
                placeholder={"작업상태를 선택해주세요."}
                autoComplete="new-password"
              />
            )}
          </LeadTableContent>
        </TableFixedHeight> */}
        <TableFixedHeight container item xs={12} sm={6} alignItems="center">
          <LeadTableTitle item xs={4}>
            <CustomText
              type="subtitle"
              bold400
              color={GRAYPALECONTENTS}
              lineSpace
            >
              우선순위
            </CustomText>
          </LeadTableTitle>
          <LeadTableContent item xs={8} pl="20px" pr="10px">
            {activityModalState === "detail" ? (
              <CustomText
                type="subtitle"
                bold400
                color={GRAYPALETITLE}
                lineSpace
              >
                {SALESACTIVITY_PRIORITY.find(
                  (item) => item.id === salesActivityData.priority
                )?.name
                  ? SALESACTIVITY_PRIORITY.find(
                      (item) => item.id === salesActivityData.priority
                    )?.name
                  : "-"}
              </CustomText>
            ) : (
              <EditDropDownListComponentBox
                locale="ko"
                showClearButton={true}
                dataSource={SALESACTIVITY_PRIORITY}
                name="priority"
                value={salesActivityData.priority}
                onChange={(e: any) => {
                  onChange(e, "dropdownList");
                }}
                placeholder={"우선순위를 선택해주세요."}
                autoComplete="new-password"
                fields={fields}
              />
            )}
          </LeadTableContent>
        </TableFixedHeight>
      </LeadTableContentsBox>
    </LeadTableCardBox>
  );
}

export default ActivityCreate;
