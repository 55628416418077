import { Grid } from "@mui/material";
import { CustomText } from "../../../styles/CustomText";
import { LoginBoxMainBox } from "../../../styles/mainStyle";
import { GRAYPALETITLE, GREENTITLE, REDTITLE } from "../../../styles/Color";
import { InputBox } from "../../../styles/theme";
import {
  CustomButtonCommon,
  CustomButtonDisabledFullWidth,
  CustomButtonPrimary,
} from "../../../styles/buttonStyle";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  NotiDataState,
  loginAccountState,
  loginState,
  passwordState,
} from "../../../system/atoms";
import { useHistory } from "react-router-dom";
import { EmployeeApi } from "../../../system/api/employeeApi";
import { Urls } from "../../../route/Urls";
import { ErrorHandler } from "../../../system/ApiService";
import { AlertModal } from "../../../components/Common/AlertModal";
import { CheckCircle } from "@phosphor-icons/react";

function validation(data: string, type: string) {
  // eslint-disable-next-line
  const exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  if (type === "email" && (exptext.test(data) === false || data.length > 100)) {
    return false;
  } else if (type === "password" && (data.length < 4 || data.length > 30)) {
    return false;
  }
  return true;
}

function LoginBox() {
  const history = useHistory();
  const [email, setEmail] = useRecoilState(loginAccountState);
  const [password, setPassword] = useState<string>("");
  const [loginButtonActive, setLoginButtonActive] = useState<boolean>(false);
  const [, setLoginStatus] = useRecoilState(loginState);
  const [, setPasswordStatus] = useRecoilState(passwordState);
  const [, setNotificationData] = useRecoilState(NotiDataState);
  // token 있으면
  const searchParams = new URLSearchParams(window.location.search);
  var token = "";
  var refreshToken = "";
  const existedToken = localStorage.getItem("accessToken");
  for (const param of searchParams) {
    if (param[0] === "accessToken") {
      token = param[1];
    } else if (param[0] === "refreshToken") {
      refreshToken = param[1];
    }
  }
  useEffect(() => {
    if (token || existedToken) {
      setLoginStatus(true);
      token && localStorage.setItem("accessToken", token);
      refreshToken && localStorage.setItem("refreshToken", refreshToken);

      history.push({
        pathname: `${Urls.leadList}`,
      });
    } else {
      setLoginStatus(false);
    }
  }, [
    setNotificationData,
    token,
    refreshToken,
    existedToken,
    history,
    setLoginStatus,
  ]);

  const onChange = (args: any, type: string) => {
    if (type === "email") {
      setEmail(args.target.value);
    } else {
      setPassword(args.target.value);
    }
  };

  const onChangeLoginState = () => {
    EmployeeApi.employeeLogin({ email, password })
      .then((res) => {
        localStorage.setItem("accessToken", res.data.tokens.accessToken);
        localStorage.setItem("refreshToken", res.data.tokens.refreshToken);
        if (res.data.isDefaultPassword) {
          setPasswordStatus(true);
          history.push({
            pathname: `${Urls.password}`,
          });
        } else {
          setLoginStatus(true);
          history.push({
            pathname: `${Urls.leadList}`,
          });

          setEmail("");
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  };

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onChangeLoginState();
    }
  };

  useEffect(() => {
    if (validation(email, "email") && validation(password, "password")) {
      setLoginButtonActive(true);
    } else {
      setLoginButtonActive(false);
    }
  }, [email, password]);

  function ssoLogin() {
    window.location.href = `${process.env.REACT_APP_API_SERVER_URI}/oauth2/authorization/azure`;
  }

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="center" alignItems="center">
        <LoginBoxMainBox item>
          <Grid container>
            <Grid container>
              {" "}
              <img
                src="/images/inbody_logo.png"
                width="100px"
                alt="title"
              ></img>
            </Grid>
            <Grid container>
              <CustomText type="superTitle" bold500>
                Pipeline 관리
              </CustomText>
            </Grid>
          </Grid>
          <Grid container mt="20" mb="20">
            <Grid container>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <CustomText
                    type="subtitle2"
                    bold500
                    color={GRAYPALETITLE}
                    mr="5px"
                  >
                    이메일
                  </CustomText>
                  {email === "" ? (
                    <CustomText type="small" color={REDTITLE} ml="5px">
                      * 이메일을 입력해 주세요.
                    </CustomText>
                  ) : (
                    <>
                      {email.length > 100 ? (
                        <CustomText type="small" color={REDTITLE} ml="5px">
                          * 이메일을 100자 이내로 입력해 주세요.
                        </CustomText>
                      ) : (
                        <>
                          {validation(email, "email") ? (
                            <CheckCircle
                              size={20}
                              color={GREENTITLE}
                              weight="bold"
                            />
                          ) : (
                            <CustomText type="small" color={REDTITLE} ml="5px">
                              * 올바른 이메일 형식이 아닙니다.
                            </CustomText>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>

                <InputBox
                  type="text"
                  onChange={(e) => onChange(e, "email")}
                  name="name"
                  placeholder="이메일을 입력해 주세요."
                  onKeyDown={handleOnKeyDown}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Grid container item xs={12} alignItems="center">
                  <CustomText
                    type="subtitle2"
                    bold500
                    color={GRAYPALETITLE}
                    mr="5px"
                  >
                    비밀번호
                  </CustomText>
                  {password === "" ? (
                    <CustomText type="small" color={REDTITLE} ml="5px">
                      * 비밀번호를 입력해 주세요.
                    </CustomText>
                  ) : (
                    <>
                      {password.length < 4 || password.length > 30 ? (
                        <CustomText type="small" color={REDTITLE} ml="5px">
                          * 비밀번호를 4자 이상 30자 이내로 입력해 주세요.
                        </CustomText>
                      ) : (
                        <>
                          {validation(password, "password") ? (
                            <CheckCircle
                              size={20}
                              color={GREENTITLE}
                              weight="bold"
                            />
                          ) : (
                            <CustomText type="small" color={REDTITLE} ml="5px">
                              * 올바른 비밀번호 형식이 아닙니다.
                            </CustomText>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
                <InputBox
                  type="password"
                  onChange={(e) => onChange(e, "password")}
                  name="name"
                  placeholder="비밀번호를 입력해 주세요. (4자 이상)"
                  onKeyDown={handleOnKeyDown}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt="20" mb="20">
            <Grid item xs={12}>
              {loginButtonActive ? (
                <CustomButtonPrimary
                  container
                  alignItems="center"
                  justifyContent="center"
                  onClick={onChangeLoginState}
                >
                  <CustomText type="subtitle2" bold400 cursorPointer>
                    로그인
                  </CustomText>
                </CustomButtonPrimary>
              ) : (
                <CustomButtonDisabledFullWidth
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  <CustomText type="subtitle2" bold400>
                    로그인
                  </CustomText>
                </CustomButtonDisabledFullWidth>
              )}

              <CustomButtonCommon
                container
                alignItems="center"
                justifyContent="center"
                onClick={ssoLogin}
              >
                <img
                  src="/images/microsoft.png"
                  width="15px"
                  alt="microsoftLogo"
                />
                <CustomText type="subtitle2" ml="15px" bold400 cursorPointer>
                  본사 직원 로그인
                </CustomText>
              </CustomButtonCommon>
            </Grid>
          </Grid>
        </LoginBoxMainBox>
      </Grid>
    </Grid>
  );
}
export default LoginBox;
