export enum Urls {
  password = "/password",
  login = "/login",
  // 조회
  leadList = "/pipeline/list",
  my = "/list/my",
  myBranch = "/list/mybranch",
  new = "/list/new",
  followUp = "/list/followup",
  answer = "/list/answer",
  leadDetail = "/list/detail/:id",
  intermediary = "/list/intermediary",
  noti = "/list/noti",
  // 등록
  registerByCase = "/register/edit/:id?",
  registerWithExcel = "/register/excel",

  // 게시판
  notice = "/notice",
  noticeEdit = "/notice/edit/:id?",
  noticeDetail = "/notice/detail/:id",
  qna = "/qna",
  qnaEdit = "/qna/edit/:id?",
  qnaDetail = "/qna/detail/:id",
  schedule = "/schedule",
  // 현황
  statusGraph = "/statusGraph",

  // 조달검사검수
  qaqc = "/qaqc",

  // 설정
  myInfo = "/myInfo",

  // 관리자 페이지
  adminUser = "/admin/user",
  adminGroupPermission = "/admin/grouppermission",
  adminGroupPermissionDetail = "/admin/grouppermission/detail/:id?",
  adminInformation = "/admin/information",
  adminProductRegister = "/admin/ProductRegister",
  adminSalesProtection = "/admin/salesprotection",

  // 대시보드
  dashBoard = "/",
}

export interface MenuItem {
  key: string;
  label: string;
  children?: MenuItem[];
  toUrl?: string;
  external?: boolean;
  requiresAdmin?: boolean;
}
export type MenuList = MenuItem[];

export const menuList: MenuList = [
  {
    key: "board",
    label: "게시판",
    children: [
      { key: "notice", label: "공지사항", toUrl: Urls.notice },
      { key: "qna", label: "질의응답", toUrl: Urls.qna },
      { key: "schedule", label: "일정", toUrl: Urls.schedule },
      { key: "qaqc", label: "조달검사검수", toUrl: Urls.qaqc },
    ],
  },
  {
    key: "list",
    label: "조회",
    children: [
      { key: "leadList", label: "Pipeline 목록", toUrl: Urls.leadList },
      { key: "my", label: "내 거래", toUrl: Urls.my },
      { key: "myBranch", label: "내 지사 거래", toUrl: Urls.myBranch },
      { key: "new", label: "신규 거래", toUrl: Urls.new },
      { key: "followUp", label: "장기 F/U", toUrl: Urls.followUp },
      { key: "intermediary", label: "중개업체", toUrl: Urls.intermediary },
      // { key: "answer", label: "신규 답변 내역", toUrl: Urls.answer },
    ],
  },
  {
    key: "register",
    label: "등록",
    children: [
      { key: "edit", label: "건별 등록", toUrl: "/register/edit/" },
      // { key: "registerWithExcel", label: "엑셀 등록", toUrl: Urls.registerWithExcel },
    ],
  },
  {
    key: "statusGraph",
    label: "현황",
    children: [
      { key: "statusReport", label: "레포트", toUrl: Urls.statusGraph },
    ],
    requiresAdmin: true,
  },
  {
    key: "sitelink",
    label: "업무 사이트",
    children: [
      {
        key: "groupware",
        label: "그룹웨어",
        toUrl: "https://groupware.weareinbody.com/",
        external: true,
      },
      {
        key: "mail",
        label: "메일",
        toUrl: "https://outlook.office.com/mail/",
        external: true,
      },
      {
        key: "erp",
        label: "ERP",
        toUrl: "https://inbody.operations.dynamics.com/",
        external: true,
      },
      {
        key: "order",
        label: "제품발주",
        toUrl: "https://order.weareinbody.com/",
        external: true,
      },
      {
        key: "crm",
        label: "고객관리(CRM)",
        toUrl: "https://crm.weareinbody.com/",
        external: true,
      },
      {
        key: "mar",
        label: "요청접수(MAR)",
        toUrl: "https://mar.weareinbody.com/",
        external: true,
      },
      {
        key: "library",
        label: "이미지풀(Inbody Library)",
        toUrl: "https://lib.weareinbody.com/",
        external: true,
      },
      {
        key: "certification",
        label: "인증",
        toUrl: "https://inbodykr.sharepoint.com/sites/certification2",
        external: true,
      },
      {
        key: "homepage",
        label: "인바디 홈페이지",
        toUrl: "https://www.inbody.co.kr/",
        external: true,
      },
      {
        key: "store",
        label: "인바디 자사몰",
        toUrl: "https://store.inbody.com/",
        external: true,
      },
      {
        key: "gm",
        label: "인바디 GM",
        toUrl: "https://gm.weareinbody.com/",
        external: true,
      },
    ],
  },
  {
    key: "admin",
    label: "설정",
    children: [
      { key: "myInfo", label: "내 정보", toUrl: Urls.myInfo },
      {
        key: "adminUser",
        label: "사용자 관리",
        toUrl: Urls.adminUser,
        requiresAdmin: true,
      },
      {
        key: "adminGroupPermission",
        label: "그룹 및 권한 관리",
        toUrl: Urls.adminGroupPermission,
        requiresAdmin: true,
      },
      {
        key: "adminInformation",
        label: "정보 관리",
        toUrl: Urls.adminInformation,
        requiresAdmin: true,
      },
      {
        key: "adminProductRegister",
        label: "제품 관리",
        toUrl: Urls.adminProductRegister,
        requiresAdmin: true,
      },
      {
        key: "adminSalesProtection",
        label: "영업 보호권 관리",
        toUrl: Urls.adminSalesProtection,
        requiresAdmin: true,
      },
    ],
  },
];
