import { atom } from "recoil";
import { PortletStatusList } from "../../types/DashBoard/Portlet";

export const initialPortletStatus = [
  {
    id: 1,
    title: "목표 달성률",
    width: "50%",
  },
  {
    id: 2,
    title: "포캐스트",
    width: "50%",
  },
  { id: 3, title: "거래 현황", width: "100%" },
  { id: 4, title: "업무 일정", width: "100%" },
];

export const portletStatusStore = atom<PortletStatusList>({
  key: "portletStatusStore",
  default: [
    {
      id: 1,
      title: "목표 달성률",
      width: "50%",
    },
    {
      id: 2,
      title: "포캐스트",
      width: "50%",
    },
    { id: 3, title: "거래 현황", width: "100%" },
    { id: 4, title: "업무 일정", width: "100%" },
  ],
});
