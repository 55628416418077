import { Grid } from "@mui/material";
import {
  GroupStatisticsEmployeesInfo,
  GroupStatisticsGroupsInfo,
  GroupStatisticsInfo,
} from "../../../system/types/Statistics";
import uuid from "react-uuid";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  TableLastContentBox,
  TableMainContentBox,
  TableMainNumberContentBox,
  TableMainTitleBox,
  TableOverflow,
  TableSubContentBox,
} from "../../../styles/statisticsTableStyle";
import { CustomText } from "../../../styles/CustomText";
interface StatusByBranchTableProps {
  data: GroupStatisticsInfo;
  searchYear: string;
  searchMonth: string;
  searchGap: number;
}

export default function StatusByBranchTable({
  data,
  searchYear,
  searchMonth,
  searchGap,
}: StatusByBranchTableProps) {
  const [titleList, setTitleList] = useState<string[]>([]);
  const [branchList, setBranchList] = useState<string[]>([]);
  useEffect(() => {
    const customDateList = [];
    for (var i = 0; i < searchGap - 1; i++) {
      if (Number(searchMonth) + i > 12) {
        customDateList.push(
          `${Number(searchYear) + 1}/${Number(searchMonth) + i - 12}`
        );
      } else {
        customDateList.push(`${searchYear}/${Number(searchMonth) + i}`);
      }
    }
    setTitleList(customDateList);
  }, [searchYear, searchMonth, searchGap]);

  const onChangeBranch = (branchName: string) => {
    const newBranchList = [...branchList];
    const indexToRemove = newBranchList.indexOf(branchName);
    if (indexToRemove !== -1) {
      newBranchList.splice(indexToRemove, 1);
    } else {
      newBranchList.push(branchName);
    }
    setBranchList(newBranchList);
  };

  return (
    <>
      <TableMainTitleBox container alignItems="center">
        <Grid item xs={0.5}></Grid>
        <Grid item xs={11.5 / searchGap} container>
          <CustomText type="body">구분</CustomText>
        </Grid>
        {titleList.map((datedt: string) => (
          <TableOverflow
            item
            xs={11.5 / searchGap}
            key={uuid()}
            container
            alignItems="center"
            justifyContent="right"
            pr="5px"
          >
            <CustomText type="body">{datedt}</CustomText>
          </TableOverflow>
        ))}
      </TableMainTitleBox>
      {data.groups.map((child: GroupStatisticsGroupsInfo) => (
        <Grid container key={uuid()} alignItems="center">
          <TableMainContentBox
            container
            onClick={() => {
              onChangeBranch(child.groupName);
            }}
            style={{ cursor: "pointer" }}
          >
            <Grid container justifyContent="center" item xs={0.5}>
              {branchList.includes(child.groupName) === true ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </Grid>
            <TableOverflow
              container
              item
              xs={11.5 / searchGap}
              alignItems="center"
            >
              <CustomText type="body">{child.groupName}</CustomText>
            </TableOverflow>
            {child.monthTotal.map((item: number) => (
              <TableMainNumberContentBox
                item
                container
                alignItems="center"
                justifyContent="right"
                xs={11.5 / searchGap}
                key={uuid()}
                pr="5px"
              >
                <CustomText type="body"> {item}</CustomText>
              </TableMainNumberContentBox>
            ))}
          </TableMainContentBox>
          {branchList.includes(child.groupName) === true && (
            <TableLastContentBox container>
              {data.employees[child.groupName].map(
                (employeedt: GroupStatisticsEmployeesInfo) => (
                  <TableSubContentBox container key={uuid()}>
                    <Grid container alignItems="center">
                      <Grid item xs={0.5}></Grid>
                      <TableOverflow item xs={11.5 / searchGap}>
                        <CustomText type="body" paddingLeft="10px">
                          {employeedt.employeeName}
                        </CustomText>
                      </TableOverflow>
                      {employeedt.monthTotal.map((employeeitem: number) => (
                        <TableMainNumberContentBox
                          item
                          xs={11.5 / searchGap}
                          key={uuid()}
                        >
                          <CustomText type="body">{employeeitem}</CustomText>
                        </TableMainNumberContentBox>
                      ))}
                    </Grid>
                  </TableSubContentBox>
                )
              )}
            </TableLastContentBox>
          )}
        </Grid>
      ))}
    </>
  );
}
