import { Grid } from "@mui/material";
import { TableContentBox } from "../../../../styles/theme";

import { LeadCustomerTableInfo } from "../../../../system/types/Customer";
import { isoDateFormatter } from "../../../../components/Common/isoDateFormatter";
import { nameFormatter } from "../../../../components/Common/nameFormatter";
import SecurityIcon from "@mui/icons-material/Security";
import { useRecoilValue } from "recoil";
import { AdminState } from "../../../../system/atoms";
import { useHistory } from "react-router-dom";
import { CustomerApi } from "../../../../system/api/customerApi";
import { ErrorHandler } from "../../../../system/ApiService";
import { AlertModal } from "../../../../components/Common/AlertModal";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../../styles/Color";
interface LeadTableListPcProps {
  data: LeadCustomerTableInfo | null;
  refreshData: () => void;
  refreshProtection: () => void;
}

function LeadTableListPc({
  data,
  refreshData,
  refreshProtection,
}: LeadTableListPcProps) {
  const adminValue = useRecoilValue(AdminState);
  const history = useHistory();
  function onMove(id: number) {
    history.push(`/list/detail/${id}`);
  }

  const updateProtection = () => {
    if (data?.id && data.salesProtection) {
      Swal.fire({
        title: "영업보호 설정을 해제하시겠습니까?",
        text: "선택하신 가망고객의 영업보호권이 해제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          CustomerApi.updateSalesProtection(data?.id, !data.salesProtection)
            .then(() => {
              refreshData();
              refreshProtection();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  };
  return (
    <>
      {data && (
        <Grid container>
          {data?.salesProtection === true ? (
            <TableContentBox
              item
              xs={0.5}
              container
              justifyContent="center"
              alignItems="center"
              style={{ cursor: "pointer" }}
              onClick={updateProtection}
            >
              <SecurityIcon />
            </TableContentBox>
          ) : (
            <TableContentBox
              item
              xs={0.5}
              container
              justifyContent="center"
              alignItems="center"
            ></TableContentBox>
          )}
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.groupName}
          </TableContentBox>
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.placeName}
          </TableContentBox>
          <TableContentBox
            item
            xs={1.5}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.address}
          </TableContentBox>
          <TableContentBox
            item
            xs={0.5}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.productFamily}
          </TableContentBox>
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.productName}
          </TableContentBox>
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.businessType}
          </TableContentBox>
          <TableContentBox
            item
            xs={0.7}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.saleType}
          </TableContentBox>
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            <Grid>[{data?.contactTypeMajor}]</Grid>
            <Grid> {data?.contactTypeSub}</Grid>
          </TableContentBox>
          <TableContentBox
            item
            xs={0.8}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.sellerName ? nameFormatter(data.sellerName) : ""}
          </TableContentBox>
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.estimatedDate}
          </TableContentBox>
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.firstMeetingDate &&
              isoDateFormatter(new Date(data.firstMeetingDate))}
          </TableContentBox>
          <TableContentBox
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center"
            style={{ cursor: "pointer" }}
            onClick={() => onMove(data?.id)}
          >
            {data?.createdAt && isoDateFormatter(new Date(data.createdAt))}
          </TableContentBox>
        </Grid>
      )}
    </>
  );
}

export default LeadTableListPc;
