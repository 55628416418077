import { useHistory, useParams } from "react-router-dom";
import { CustomText } from "../../styles/CustomText";
import { NoticeInfo } from "../../system/types/Board";
import { useCallback, useEffect, useState } from "react";
import { NoticeApi } from "../../system/api/noticeApi";
import { ErrorHandler } from "../../system/ApiService";
import { AlertModal } from "../../components/Common/AlertModal";
import { CustomButtonGreen, CustomButtonRed } from "../../styles/buttonStyle";
import { Grid, styled } from "@mui/material";
import { useRecoilValue } from "recoil";
import { AdminState } from "../../system/atoms";
import { MainTableChip, TableNoticeTitleBox } from "../../styles/theme";
import { isoDateFormatter } from "../../components/Common/isoDateFormatter";
import { GRAYPALEBORDER, GREENCONTENTS, REDTITLE } from "../../styles/Color";
import Swal from "sweetalert2";
import { InitNotcieInfo } from "../../system/types/initObject";
import { AttachmentFileBox } from "../../styles/byCaseStyle";
import { AttachmentFilesInfo } from "../../system/types/MeetingNote";
import { TokenApi } from "../../system/api/tokenApi";
import DownloadIcon from "@mui/icons-material/Download";

interface Params {
  id: string;
}

function NoticeDetailPage() {
  const { id } = useParams<Params>();
  const detailId = parseInt(id);
  const [data, setData] = useState<NoticeInfo>(InitNotcieInfo);
  const adminValue = useRecoilValue(AdminState);
  const history = useHistory();
  const todayDate = new Date();
  function onMoveEdit() {
    history.push(`/notice/edit/${id}`);
  }

  const getNotice = useCallback(() => {
    NoticeApi.getNoticeDetail(detailId)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [detailId]);

  var submitcheck = false;

  const deleteNotice = () => {
    if (submitcheck === false) {
      submitcheck = true;
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 공지사항이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          NoticeApi.deleteNotice(detailId)
            .then(() => {
              AlertModal("success", "선택하신 공지사항이 삭제되었습니다.");
              history.push(`/notice`);
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  };

  useEffect(() => {
    getNotice();
  }, [getNotice]);

  const [sasToken, setSasToken] = useState("");

  useEffect(() => {
    TokenApi.getSasToken()
      .then((res) => {
        const sasUrlText = res.data.sasUrl;
        const questionMarkIndex = sasUrlText.indexOf("?");
        if (questionMarkIndex !== -1) {
          setSasToken(sasUrlText.slice(questionMarkIndex));
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  function handleDownloadFromStorage(filename: string) {
    // 로컬 스토리지에서 Blob URL 가져오기
    const blobURL = localStorage.getItem("downloadedFile");
    if (!blobURL) {
      console.error("No file downloaded yet.");
      return;
    }
    // 새로운 Blob 생성
    fetch(blobURL)
      .then((response) => response.blob())
      .then((blob) => {
        const newBlob = new Blob([blob], { type: blob.type });
        // Blob을 다운로드
        const url = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        // 다운로드가 완료되면 URL 객체를 해제
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error reading file from local storage:", error);
      });
  }

  function handleDownload(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Blob을 로컬 스토리지에 저장
        const blobURL = URL.createObjectURL(blob);
        localStorage.setItem("downloadedFile", blobURL);
        // 다운로드 실행
        handleDownloadFromStorage(filename);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  return (
    <>
      <CustomText type="title">공지사항 상세</CustomText>
      {adminValue && (
        <Grid container justifyContent="right" margin="5px 0px">
          <Grid mr="5px">
            <CustomButtonGreen onClick={onMoveEdit}>수정</CustomButtonGreen>
          </Grid>
          <Grid>
            <CustomButtonRed onClick={deleteNotice}>삭제</CustomButtonRed>
          </Grid>
        </Grid>
      )}
      <Grid container mt="10px">
        <TableNoticeTitleBox container justifyContent="space-between">
          <Grid container item padding="0px 10px" xs={12} sm={6}>
            {data.mainStartDate &&
              data.mainEndDate &&
              new Date(data.mainStartDate) < todayDate &&
              new Date(data.mainEndDate) > todayDate && (
                <MainTableChip mr="10px">중요</MainTableChip>
              )}
            {data?.title}
          </Grid>
          <Grid
            item
            padding="0px 10px"
            xs={12}
            sm={6}
            container
            justifyContent="right"
          >
            {data?.createBy?.name} /{" "}
            {data?.createdAt ? isoDateFormatter(new Date(data?.createdAt)) : ""}
          </Grid>
        </TableNoticeTitleBox>
        {data.content && (
          <TableContentPreLine item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
          </TableContentPreLine>
        )}
      </Grid>
      <AttachmentTableContentBox container>
        <AttachmentTitleBox container>첨부파일</AttachmentTitleBox>
        <Grid container justifyContent="right">
          {data.attachmentFiles.length === 0 ? (
            <Grid container item xs={12} justifyContent="center" mt="10px">
              첨부 파일 없음
            </Grid>
          ) : (
            <>
              {data.attachmentFiles.map(
                (dt: AttachmentFilesInfo, index: number) => (
                  <AttachmentFileBox
                    key={index}
                    container
                    item
                    xs={12}
                    onClick={() => {
                      handleDownload(dt.filePath + sasToken, dt.filename);
                    }}
                  >
                    <DownloadIcon color="action" />

                    <Grid ml="5px">{dt.filename}</Grid>
                  </AttachmentFileBox>
                )
              )}
            </>
          )}
        </Grid>
      </AttachmentTableContentBox>
    </>
  );
}

export default NoticeDetailPage;

const TableContentPreLine = styled(Grid)({
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
  padding: "10px",
  minHeight: "300px",
});

const AttachmentTitleBox = styled(Grid)({
  padding: "5px 0px",
  borderBottom: `1px solid ${GRAYPALEBORDER}`,
});

const AttachmentTableContentBox = styled(Grid)({
  border: `1px solid ${GRAYPALEBORDER}`,
  padding: "10px 20px",
  marginTop: "10px",
  textAlign: "center",
  minHeight: "40px",
  overflow: "auto",
  overflowWrap: "anywhere",
});
