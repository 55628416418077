import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

abstract class _HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(url?: string) {
    const baseURL = url ? url : `${window.location.origin}`;

    this.instance = axios.create({
      baseURL,
    });
    this.instance.defaults.withCredentials = true;
  }
}

export default class HttpClient extends _HttpClient {
  public constructor(url?: string) {
    super(url);
    this.instance.interceptors.request.use(this.requestInterceptor, (error) =>
      Promise.reject(error)
    );
    this.instance.interceptors.response.use(this.responseInterceptor, (error) =>
      Promise.reject(error)
    );
  }

  private requestInterceptor = async (config: AxiosRequestConfig) => {
    let idTokenObj;
    let refreshTokenObj;
    for (var key in localStorage) {
      if (key === "accessToken") {
        idTokenObj = localStorage.getItem(key);
        break;
      }
    }
    for (var refreshTokenKey in localStorage) {
      if (refreshTokenKey === "refreshToken") {
        refreshTokenObj = localStorage.getItem(refreshTokenKey);
        break;
      }
    }
    if (idTokenObj) {
      this.setHeaders(config, "accessToken", idTokenObj);
    }
    if (refreshTokenObj) {
      this.setHeaders(config, "refreshToken", refreshTokenObj);
    }
    return config;
  };

  private responseInterceptor = (response: AxiosResponse) => {
    const newAccessToken = response.headers["new-access-token"];
    if (newAccessToken) {
      localStorage.setItem("accessToken", newAccessToken);
    }
    return response;
  };

  setHeaders(config: AxiosRequestConfig, type: string, token: string) {
    if (config.headers !== undefined) {
      if (type === "accessToken") {
        config.headers["Authorization"] = `Bearer ${token}`;
      } else {
        config.headers["Refresh-Token"] = `${token}`;
      }
    }
  }

  post<Request, Response = any>(
    url: string,
    data?: Request,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.instance.post(url, data, config);
  }

  get<Response = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.instance.get(url, config);
  }

  put<Request, Response = any>(
    url: string,
    data?: Request,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.instance.put(url, data, config);
  }

  delete<Response = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Response>> {
    return this.instance.delete(url, config);
  }
}

export class HttpUrlClient {
  protected prefix: string;
  private client: HttpClient;

  public constructor(client: HttpClient, prefix?: string) {
    this.client = client;
    this.prefix = prefix || "";
  }

  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise(
      (resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
        this.client
          .post<T>(this.prefix + url, data, config)
          .then((res) => resolve(res.data))
          .catch((error) => reject(error));
      }
    );
  }

  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return new Promise(
      (resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
        this.client
          .get<T>(this.prefix + url, config)
          .then((res) => resolve(res.data))
          .catch((error) => reject(error));
      }
    );
  }

  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise(
      (resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
        this.client
          .put<T>(this.prefix + url, data, config)
          .then((res) => resolve(res.data))
          .catch((error) => reject(error));
      }
    );
  }

  delete<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise(
      (resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
        this.client
          .delete<T>(this.prefix + url, {
            ...config,
            data: data,
          })
          .then((res) => resolve(res.data))
          .catch((error) => reject(error));
      }
    );
  }
}
