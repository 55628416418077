import {
  Box,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  styled,
  Tooltip,
} from "@mui/material";
import { CustomText } from "../../../../../styles/CustomText";
import {
  DropDownListComponentBox,
  InputBox,
  LeadTableCardBox,
  LeadTableContentsBox,
  LeadTableTitlBox,
  ModalStyle,
  PointerItem,
} from "../../../../../styles/theme";
import {
  LeadOrderCreateInfo,
  LeadOrdersInfo,
  QaQcDetailInfo,
} from "../../../../../system/types/Customer";
import uuid from "react-uuid";
import {
  GREENCONTENTS,
  GREENTITLE,
  ORANGETITLE,
  REDTITLE,
} from "../../../../../styles/Color";
import { useCallback, useEffect, useState } from "react";
import { InitLeadOrderCreateInfo } from "../../../../../system/types/initObject";
import { AlertModal } from "../../../../../components/Common/AlertModal";
import EditNoteIcon from "@mui/icons-material/EditNote";
import CheckIcon from "@mui/icons-material/Check";
import { ErrorHandler } from "../../../../../system/ApiService";
import { TableLastContentBox } from "../../../../../styles/statisticsTableStyle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { OrderApi } from "../../../../../system/api/orderApi";
import { CustomButtonGreen } from "../../../../../styles/buttonStyle";
import { X } from "@phosphor-icons/react";
import { ProductApi } from "../../../../../system/api/productApi";
import { FilteringEventArgs } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import Swal from "sweetalert2";
import { SearchProductDataInfo } from "../../../../../system/types/Product";
interface LeadProductProps {
  data: LeadOrdersInfo[] | null;
  refreshData: () => void;
  qaqcState: boolean;
  handleOpen: (child: LeadOrdersInfo) => void;
  customerId: number | null;
  sellerId: number | null;
  productFamilyList: string[];
}

function orderValidation(data: LeadOrderCreateInfo) {
  if (data.productId === null) {
    AlertModal("check", "제품을 선택해 주세요.");
    return false;
  }
  return true;
}

function LeadProduct({
  data,
  refreshData,
  qaqcState,
  handleOpen,
  customerId,
  productFamilyList,
  sellerId,
}: LeadProductProps) {
  const [orderData, setOrderData] = useState<LeadOrderCreateInfo>(
    InitLeadOrderCreateInfo
  );
  const [orderId, setOrderId] = useState<number | null>(null);
  const [productFamily, setProductFamily] = useState("");
  const [productList, setProductList] = useState<SearchProductDataInfo[]>([]);
  const [productPriceList, setProductPriceList] = useState<
    { name: string; title: string; price: number }[]
  >([]);

  const getProduct = useCallback((searchText: string) => {
    ProductApi.searchProduct(searchText)
      .then((res) => {
        setProductList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);
  const productFields: Object = {
    dataSource: productList,
    value: "id",
    text: "name",
    child: "children",
  };

  // 자동 숫자 ,
  const formatNumber = (num: number) => {
    return num.toLocaleString();
  };

  const removeCommas = (str: string) => {
    return str.replace(/,/g, "");
  };

  const getPrice = useCallback((productId: number) => {
    ProductApi.searchProductPrice(productId)
      .then((res) => {
        var prices_list = [];
        if (res.data[0]["retailPrice"]) {
          prices_list.push({
            name: "retailPrice",
            title: "권장소비자가",
            price: res.data[0]["retailPrice"],
          });
        }
        if (res.data[0]["wholesalePrice"]) {
          prices_list.push({
            name: "wholesalePrice",
            title: "딜러가",
            price: res.data[0]["wholesalePrice"],
          });
        }
        if (res.data[0]["partnerPrice"]) {
          prices_list.push({
            name: "partnerPrice",
            title: "파트너가",
            price: res.data[0]["partnerPrice"],
          });
        }
        if (prices_list) {
          setProductPriceList(prices_list);
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, []);

  useEffect(() => {
    if (orderData.productId) {
      getPrice(orderData.productId);
    }
  }, [orderData.productId]);

  function addProductPrice(dt: LeadOrdersInfo | LeadOrderCreateInfo) {
    const addResult = dt.quantity * dt.price * (100 - dt.discountRate) * 0.01;
    return addResult;
  }
  function addAllProductPrice(dtList: LeadOrdersInfo[]) {
    return dtList.reduce((total, item) => total + addProductPrice(item), 0);
  }

  const [orderOpen, setOrderOpen] = useState(false);
  const orderHandleClose = () => {
    setOrderOpen(false);
    setOrderData(InitLeadOrderCreateInfo);
    setOrderId(null);
  };
  function addOrderData() {
    if (customerId && sellerId) {
      setProductFamily("");
      setOrderOpen(true);
      setOrderData({
        ...orderData,
        customerId: customerId,
        sellerId: sellerId,
      });
    }
  }

  function onSave() {
    if (orderValidation(orderData)) {
      if (orderId) {
        OrderApi.updateOrder(orderId, orderData)
          .then(() => {
            AlertModal("success", "제품 정보가 수정되었습니다.");
            setOrderOpen(false);
            refreshData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      } else {
        OrderApi.createOrder(orderData)
          .then(() => {
            AlertModal("success", "작성하신 제품이 추가되었습니다.");
            setOrderOpen(false);
            refreshData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    }
  }

  const onChangeProductFamily = (args: any) => {
    setProductFamily(args.target.value);
  };

  const onFiltering = (args: FilteringEventArgs, queryData: any) => {
    let query = new Query();
    query =
      args.text !== ""
        ? query.where("name", "contains", args.text, true)
        : query;
    args.updateData(queryData, query);
  };

  const onChange = (args: any) => {
    setOrderData({
      ...orderData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeNum = (args: any) => {
    const numericValue = parseFloat(removeCommas(args.target.value));
    setOrderData({
      ...orderData,
      [(args.target as HTMLInputElement).name]: numericValue,
    });
  };
  // 가격
  const [value, setValue] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    const productPrice = productPriceList.find(
      (ele) => ele.title === (event.target as HTMLInputElement).value
    )?.price;
    if (productPrice) {
      setOrderData({ ...orderData, price: productPrice });
    }
  };
  const deleteOrder = (id: number | undefined) => {
    if (id) {
      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 제품이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          OrderApi.deleteOrder(id)
            .then(() => {
              AlertModal("success", "선택하신 제품이 삭제되었습니다.");
              refreshData();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            });
        }
      });
    }
  };
  const onChangeOrder = (item: LeadOrdersInfo) => {
    setProductFamily(item.product.family);
    setOrderData({
      ...orderData,
      customerId: customerId,
      discountRate: item.discountRate,
      price: item.price,
      productId: item.product.id ? item.product.id : null,
      quantity: item.quantity,
    });
    if (item.product.id) {
      setValue("write");
      setProductList([
        { id: item.product.id, name: item.product.name, launchDate: "" },
      ]);
    }
    if (item.id) {
      setOrderId(item.id);
    }
    setOrderOpen(true);
  };

  return (
    <>
      <LeadTableCardBox style={{ marginTop: "20px" }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          padding="10px"
        >
          <CustomText type="subtitle">제품 정보</CustomText>
          <Chip
            variant="outlined"
            color="success"
            size="small"
            label="추가"
            icon={<AddIcon />}
            style={{ cursor: "pointer", marginRight: "5px" }}
            onClick={addOrderData}
          />
        </Grid>
        <LeadTableContentsBox container item xs={12}>
          {data && data.length > 0 ? (
            <Grid container item xs={12}>
              <LeadTableTitlBox container item xs={12} alignItems="center">
                <Grid container justifyContent="center" item xs={2}>
                  제품군
                </Grid>
                <Grid container justifyContent="center" item xs={2}>
                  제품명
                </Grid>
                <Grid container justifyContent="center" item xs={1}>
                  수량
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  item
                  xs={qaqcState ? 2 : 3}
                >
                  단가
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  item
                  xs={qaqcState ? 2 : 3}
                >
                  합계
                </Grid>
                {qaqcState && (
                  <Grid container justifyContent="center" item xs={2}>
                    조달검사검수
                  </Grid>
                )}
                <Grid container item xs={1}></Grid>
              </LeadTableTitlBox>
              {data.map((child: LeadOrdersInfo, index: number) => (
                <TableLastContentBox
                  container
                  key={uuid()}
                  padding="10px 0px"
                  alignItems="center"
                >
                  <Grid container justifyContent="center" item xs={2}>
                    <CustomText type="body" noWrap>
                      {child?.product?.family}
                    </CustomText>
                  </Grid>
                  <Grid container justifyContent="center" item xs={2}>
                    <CustomText type="body" noWrap>
                      {child?.product?.name}
                    </CustomText>
                  </Grid>
                  <Grid container justifyContent="center" item xs={1}>
                    <CustomText type="body" noWrap>
                      {child?.quantity}
                    </CustomText>
                  </Grid>
                  <Grid
                    container
                    justifyContent="right"
                    item
                    xs={qaqcState ? 2 : 3}
                    pr="10px"
                  >
                    <CustomText type="body" noWrap>
                      {child?.price?.toLocaleString()} 원
                    </CustomText>
                  </Grid>
                  <Grid
                    container
                    justifyContent="right"
                    item
                    xs={qaqcState ? 2 : 3}
                    pr="10px"
                  >
                    <CustomText type="body" noWrap>
                      {addProductPrice(child)?.toLocaleString()} 원
                    </CustomText>
                  </Grid>
                  {qaqcState && (
                    <Grid container justifyContent="center" item xs={2}>
                      {child.qaQc === null ? (
                        <TableContentRequestBox
                          item
                          xs={12}
                          container
                          justifyContent="center"
                          alignItems="center"
                          onClick={() => handleOpen(child)}
                        >
                          <EditNoteIcon />
                          작성하기
                        </TableContentRequestBox>
                      ) : (
                        <>
                          {child?.qaQc &&
                          (child?.qaQc as QaQcDetailInfo).isChecked ? (
                            <TableContentRequestCompleteBox
                              item
                              xs={12}
                              container
                              justifyContent="center"
                              alignItems="center"
                            >
                              <CheckIcon />
                              확인완료
                            </TableContentRequestCompleteBox>
                          ) : (
                            <TableContentEditBox
                              item
                              xs={12}
                              container
                              justifyContent="center"
                              alignItems="center"
                              onClick={() => handleOpen(child)}
                            >
                              <EditNoteIcon />
                              수정하기
                            </TableContentEditBox>
                          )}
                        </>
                      )}
                    </Grid>
                  )}

                  <Grid container item xs={1} justifyContent="center">
                    <Tooltip title="수정">
                      <EditIconButton onClick={() => onChangeOrder(child)}>
                        <EditIcon fontSize="small" />
                      </EditIconButton>
                    </Tooltip>
                    <Tooltip title="삭제">
                      <DeleteIconButton onClick={() => deleteOrder(child?.id)}>
                        <DeleteIcon fontSize="small" />
                      </DeleteIconButton>
                    </Tooltip>
                  </Grid>
                </TableLastContentBox>
              ))}
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                pt="5px"
              >
                <Grid item xs={12} sm={6} pl="10px">
                  * 모든 금액 VAT 포함
                </Grid>
                <Grid container item xs={12} sm={6} justifyContent="right">
                  <Grid item pr="30px">
                    총 합계
                  </Grid>
                  <Grid item pr="10px">
                    {addAllProductPrice(data).toLocaleString()} 원
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container item xs={12} justifyContent="center" margin="20px">
              조회된 데이터가 없습니다.
            </Grid>
          )}
        </LeadTableContentsBox>
      </LeadTableCardBox>
      <Modal
        open={orderOpen}
        onClose={orderHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title">
                제품 {orderId ? "수정" : "추가"}
              </CustomText>
              <PointerItem onClick={orderHandleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <CustomText type="subtitle">제품군</CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                <DropDownListComponentBox
                  locale="ko"
                  dataSource={productFamilyList}
                  name="family"
                  value={productFamily}
                  onChange={onChangeProductFamily}
                  placeholder={"제품군을 선택해주세요."}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomText type="subtitle">제품명</CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                <DropDownListComponentBox
                  locale="ko"
                  dataSource={productList}
                  filtering={(args: any) => onFiltering(args, productList)}
                  allowFiltering={true}
                  beforeOpen={() => getProduct(productFamily)}
                  fields={productFields}
                  name="productId"
                  value={orderData.productId}
                  onChange={onChange}
                  placeholder={"제품명을 선택해주세요."}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomText type="subtitle">수량</CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                <InputBox
                  type="text"
                  name="quantity"
                  placeholder="수량을 입력하세요."
                  autoComplete="new-password"
                  onChange={onChangeNum}
                  value={formatNumber(orderData.quantity)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomText type="subtitle">단가(VAT포함)</CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                {orderData.productId ? (
                  <Grid container>
                    <RadioGroup value={value} onChange={handleChange}>
                      {productPriceList.map((priceDt) => {
                        return (
                          <FormControlLabel
                            key={priceDt.title}
                            value={priceDt.title}
                            control={<Radio color="default" />}
                            label={
                              <Grid
                                container
                                item
                                xs={12}
                                style={{ width: "300px", marginTop: "-3px" }}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  justifyContent="center"
                                  style={{
                                    width: "200px",
                                    textAlign: "center",
                                  }}
                                >
                                  [ {priceDt.title} ]
                                </Grid>
                                <Grid item xs={6} justifyItems="right">
                                  {priceDt.price.toLocaleString()} 원
                                </Grid>
                              </Grid>
                            }
                          />
                        );
                      })}
                      <FormControlLabel
                        value="write"
                        control={<Radio color="default" />}
                        label={
                          <CustomText type="subtitle" bold400 marginTop="-3px">
                            직접 입력
                          </CustomText>
                        }
                      />
                    </RadioGroup>
                    {value === "write" && (
                      <Grid container alignItems="start" item xs={12}>
                        <InputBox
                          type="text"
                          name="price"
                          onChange={onChangeNum}
                          value={formatNumber(orderData.price)}
                          placeholder="가격을 작성해주세요."
                          autoComplete="new-password"
                        />
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <CustomText type="subtitle" bold400 color={REDTITLE}>
                    * 제품을 선택해주세요.
                  </CustomText>
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomText type="subtitle">합계(VAT포함)</CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                {addProductPrice(orderData)?.toLocaleString()} 원
              </Grid>

              <Grid container item xs={12} justifyContent="right">
                <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default LeadProduct;

const TableContentRequestBox = styled(Grid)({
  color: "#47B998",
  overflow: "auto",
  overflowWrap: "anywhere",
  cursor: "pointer",
  fontWeight: 600,
  "&:hover": {
    color: GREENTITLE,
  },
});

const TableContentEditBox = styled(Grid)({
  color: "#F59210",
  overflow: "auto",
  overflowWrap: "anywhere",
  cursor: "pointer",
  fontWeight: 600,
  "&:hover": {
    color: ORANGETITLE,
  },
});

const TableContentRequestCompleteBox = styled(Grid)({
  overflow: "auto",
  overflowWrap: "anywhere",
});

const EditIconButton = styled(IconButton)({
  "&:hover": {
    color: ORANGETITLE,
  },
});

const DeleteIconButton = styled(IconButton)({
  "&:hover": {
    color: REDTITLE,
  },
});
