import { Box, styled } from "@mui/material";

export const DahsboardHeaderWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const DahsboardHeaderTitle = styled("h1")({
  fontSize: "1.25rem",
  display: "flex",
  padding: "8px 0",
});
