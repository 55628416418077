import { Grid, styled } from "@mui/material";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  LineSeries,
  Legend,
  Tooltip,
  Category,
  AxisModel,
  ColumnSeries,
  DataLabel,
  Highlight,
} from "@syncfusion/ej2-react-charts";
import { DropDownListComponentBox } from "../../../styles/theme";
import { MONTH_LIST } from "../../../system/constants";
import { useEffect, useState } from "react";
import {
  ProductFamilyInfo,
  ProductInfo,
  ProductStatisticsDataInfo,
} from "../../../system/types/Statistics";
import { StatisticsApi } from "../../../system/api/statisticsApi";
import { ErrorHandler } from "../../../system/ApiService";
import { AlertModal } from "../../../components/Common/AlertModal";
import { CustomText } from "../../../styles/CustomText";
import StatusByProductTable from "./StatusByProductTable";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TimelineIcon from "@mui/icons-material/Timeline";
import EqualizerIcon from "@mui/icons-material/Equalizer";
interface StatusByProductProps {
  yearList: string[];
}

function StatusByProduct(this: any, { yearList }: StatusByProductProps) {
  const [productFamilyData, setProductFamilyData] = useState<any>(null);
  const [allProductData, setAllProductData] = useState<any>(null);
  const [productData, setProductData] = useState<ProductStatisticsDataInfo[]>(
    []
  );
  const [groupName, setGroupName] = useState<string[]>([]);
  const [searchYear, setSearchYear] = useState(
    new Date().getFullYear().toString()
  );
  const [searchMonth, setSearchMonth] = useState((1).toString());
  const [searchGap, setSearchGap] = useState("12");
  const [productList, setProductList] = useState<string[]>([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [tableOpen, setTableOpen] = useState(false);
  const [chartType, setChartType] = useState("line");

  const onChangeChartType = (type: string) => {
    setChartType(type);
  };

  const onChangeDate = (args: any, type: string) => {
    if (type === "year") {
      setSearchYear(args.target.value.toString());
    } else if (type === "month") {
      setSearchMonth(args.target.value.toString());
    } else if (type === "gap") {
      setSearchGap(args.target.value.toString());
    } else if (type === "product") {
      setSelectedProduct(args.target.value);
    }
  };
  const onChangeTableOpen = () => {
    setTableOpen(!tableOpen);
  };
  useEffect(() => {
    StatisticsApi.groupProductFamily(
      Number(searchYear),
      Number(searchMonth),
      Number(searchGap)
    )
      .then((res) => {
        const transformedData = Object.keys(res.data).map((productName) => ({
          productName,
          data: res.data[productName].map((item: ProductFamilyInfo) => ({
            x: item.groupName,
            y: item.total,
          })),
        }));
        setProductFamilyData(transformedData);
        const ProductFamilyNameData = Object.keys(res.data);
        setProductList(ProductFamilyNameData);
        if (ProductFamilyNameData.length > 0) {
          setSelectedProduct(ProductFamilyNameData[0]);
          setGroupName(
            res.data[ProductFamilyNameData[0]].map(
              (item: ProductFamilyInfo) => item.groupName
            )
          );
        }
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });

    StatisticsApi.groupProduct(
      Number(searchYear),
      Number(searchMonth),
      Number(searchGap)
    )
      .then((res) => {
        setAllProductData(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [searchYear, searchMonth, searchGap]);
  useEffect(() => {
    if (selectedProduct && allProductData && allProductData[selectedProduct]) {
      const transformedData = Object.entries(
        allProductData[selectedProduct]
      ).map(([productName, products]: [string, any]) => ({
        productName: productName,
        data: products.map((item: ProductInfo) => ({
          x: item.groupName,
          y: item.total,
        })),
      }));
      setProductData(transformedData);
    }
  }, [selectedProduct, allProductData]);

  const primaryxAxis: AxisModel = {
    valueType: "Category",
  };

  const margin = { left: 25, right: 25, top: 0, bottom: 0 };

  const combineValues = (obj: any) => {
    return Object.values(obj).join("");
  };

  function yearValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}년
        </CustomText>
      </Grid>
    );
  }

  function monthValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}월 부터
        </CustomText>
      </Grid>
    );
  }

  function gapValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}개월
        </CustomText>
      </Grid>
    );
  }

  function branchValueTemplate(item: any) {
    return (
      <Grid container justifyContent="center">
        <CustomText type="subtitle" bold400>
          {combineValues(item)}
        </CustomText>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" mt="50px">
      <Grid container justifyContent="space-between" alignItems="center">
        {chartType === "line" ? (
          <Grid container item alignItems="center" xs={12} sm={6}>
            <TimelineIcon fontSize="large" />
            <EqualizerIcon
              fontSize="large"
              color="disabled"
              onClick={() => {
                onChangeChartType("column");
              }}
              style={{ cursor: "pointer" }}
            />
          </Grid>
        ) : (
          <Grid container item alignItems="center" xs={12} sm={6}>
            <TimelineIcon
              fontSize="large"
              color="disabled"
              onClick={() => {
                onChangeChartType("line");
              }}
              style={{ cursor: "pointer" }}
            />
            <EqualizerIcon fontSize="large" />
          </Grid>
        )}

        <Grid container justifyContent="right" item xs={12} sm={6} mb="10px">
          <DropdownBox mr="5px">
            <DropDownListComponentBox
              popupWidth={120}
              dataSource={yearList}
              placeholder="년도"
              onChange={(e: any) => {
                onChangeDate(e, "year");
              }}
              value={searchYear.toString()}
              valueTemplate={yearValueTemplate}
            />
          </DropdownBox>
          <DropdownBox mr="5px">
            <DropDownListComponentBox
              popupWidth={120}
              dataSource={MONTH_LIST}
              placeholder="월"
              onChange={(e: any) => {
                onChangeDate(e, "month");
              }}
              value={searchMonth.toString()}
              valueTemplate={monthValueTemplate}
            />
          </DropdownBox>
          <DropdownBox>
            <DropDownListComponentBox
              popupWidth={120}
              dataSource={MONTH_LIST}
              placeholder="기간"
              onChange={(e: any) => {
                onChangeDate(e, "gap");
              }}
              value={searchGap.toString()}
              valueTemplate={gapValueTemplate}
            />
          </DropdownBox>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {chartType === "line" && (
          <ChartComponent
            id="productFamilyChart"
            primaryXAxis={primaryxAxis}
            tooltip={{ enable: true }}
            legendSettings={{
              enableHighlight: true,
              alignment: "Center",
              position: "Right",
            }}
            title="지사별 Pipeline 현황_제품군"
            width="100%"
            margin={margin}
          >
            <Inject
              services={[
                ColumnSeries,
                DataLabel,
                Tooltip,
                LineSeries,
                Category,
                Legend,
                Highlight,
              ]}
            />
            <SeriesCollectionDirective>
              {productFamilyData &&
                productFamilyData.map(
                  (child: ProductStatisticsDataInfo, index: number) => (
                    <SeriesDirective
                      key={index}
                      dataSource={child.data}
                      xName="x"
                      yName="y"
                      name={child.productName}
                      width={2}
                      marker={{
                        visible: true,
                        width: 5,
                        height: 5,
                        shape: "Circle",
                        isFilled: true,
                      }}
                      type="Line"
                    ></SeriesDirective>
                  )
                )}
            </SeriesCollectionDirective>
          </ChartComponent>
        )}
        {chartType === "column" && (
          <ChartComponent
            id="productFamilyChart_Column"
            primaryXAxis={primaryxAxis}
            tooltip={{ enable: true }}
            legendSettings={{
              enableHighlight: true,
              alignment: "Center",
              position: "Right",
            }}
            title="지사별 Pipeline 현황_제품군"
            width="100%"
            margin={margin}
          >
            <Inject
              services={[
                ColumnSeries,
                DataLabel,
                Tooltip,
                Category,
                Legend,
                Highlight,
              ]}
            />
            <SeriesCollectionDirective>
              {productFamilyData &&
                productFamilyData.map(
                  (child: ProductStatisticsDataInfo, index: number) => (
                    <SeriesDirective
                      key={index}
                      dataSource={child.data}
                      xName="x"
                      yName="y"
                      name={child.productName}
                      width={2}
                      marker={{
                        visible: true,
                        width: 5,
                        height: 5,
                        shape: "Circle",
                        isFilled: true,
                      }}
                      type="Column"
                    ></SeriesDirective>
                  )
                )}
            </SeriesCollectionDirective>
          </ChartComponent>
        )}
      </Grid>
      <Grid item xs={12} mt="50px">
        <Grid container justifyContent="right" item xs={12} mb="10px">
          <DropdownBox mr="5px">
            <DropDownListComponentBox
              popupWidth={150}
              dataSource={productList}
              placeholder="지사"
              onChange={(e: any) => {
                onChangeDate(e, "product");
              }}
              value={selectedProduct}
              valueTemplate={branchValueTemplate}
            />
          </DropdownBox>
        </Grid>
        <Grid item xs={12}>
          {chartType === "line" && (
            <ChartComponent
              id="productChart"
              primaryXAxis={primaryxAxis}
              tooltip={{ enable: true }}
              legendSettings={{
                enableHighlight: true,
                alignment: "Center",
                position: "Right",
              }}
              title="지사별 Pipeline 현황_모델명"
              width="100%"
              margin={margin}
            >
              <Inject
                services={[
                  ColumnSeries,
                  DataLabel,
                  Tooltip,
                  LineSeries,
                  Category,
                  Legend,
                  Highlight,
                ]}
              />
              <SeriesCollectionDirective>
                {productData &&
                  selectedProduct &&
                  productData.map(
                    (child: ProductStatisticsDataInfo, index: number) => (
                      <SeriesDirective
                        key={index}
                        dataSource={child.data}
                        xName="x"
                        yName="y"
                        name={child.productName}
                        width={2}
                        marker={{
                          visible: true,
                          width: 5,
                          height: 5,
                          shape: "Circle",
                          isFilled: true,
                        }}
                        type="Line"
                      ></SeriesDirective>
                    )
                  )}
              </SeriesCollectionDirective>
            </ChartComponent>
          )}
          {chartType === "column" && (
            <ChartComponent
              id="productChart"
              primaryXAxis={primaryxAxis}
              tooltip={{ enable: true }}
              legendSettings={{
                enableHighlight: true,
                alignment: "Center",
                position: "Right",
              }}
              title="지사별 Pipeline 현황_모델명"
              width="100%"
              margin={margin}
            >
              <Inject
                services={[
                  ColumnSeries,
                  DataLabel,
                  Tooltip,
                  Category,
                  Legend,
                  Highlight,
                ]}
              />
              <SeriesCollectionDirective>
                {productData &&
                  selectedProduct &&
                  productData.map(
                    (child: ProductStatisticsDataInfo, index: number) => (
                      <SeriesDirective
                        key={index}
                        dataSource={child.data}
                        xName="x"
                        yName="y"
                        name={child.productName}
                        width={2}
                        marker={{
                          visible: true,
                          width: 5,
                          height: 5,
                          shape: "Circle",
                          isFilled: true,
                        }}
                        type="Column"
                      ></SeriesDirective>
                    )
                  )}
              </SeriesCollectionDirective>
            </ChartComponent>
          )}
        </Grid>
      </Grid>
      <TableOpenBox container onClick={onChangeTableOpen}>
        {tableOpen ? (
          <Grid style={{ cursor: "pointer" }}>
            <ArrowDropUpIcon />
            지사별 Pipeline 현황-제품 표 닫기
          </Grid>
        ) : (
          <Grid style={{ cursor: "pointer" }}>
            <ArrowDropDownIcon />
            지사별 Pipeline 현황-제품 표 펼쳐보기
          </Grid>
        )}
      </TableOpenBox>
      {tableOpen && (
        <Grid container mt="20px">
          <StatusByProductTable
            allProductData={allProductData}
            productFamilyData={productFamilyData}
            groupName={groupName}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default StatusByProduct;

const DropdownBox = styled(Grid)({
  width: "120px",
});

const TableOpenBox = styled(Grid)({
  marginTop: "50px",
  textDecoration: "underline",
});
