import { Grid, Box, Modal } from "@mui/material";
import { CustomText } from "../../styles/CustomText";
import { CustomButtonGreen } from "../../styles/buttonStyle";
import {
  ScheduleComponent,
  Month,
  Inject,
  ViewsDirective,
  ViewDirective,
  EventRenderedArgs,
} from "@syncfusion/ej2-react-schedule";
import { useEffect, useRef, useState } from "react";
import { ModalStyle, PointerItem } from "../../styles/theme";
import { X } from "@phosphor-icons/react";
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import * as gregorian from "../../../node_modules/cldr-data/main/ko/ca-gregorian.json";
import * as numbers from "../../../node_modules/cldr-data/main/ko/numbers.json";
import * as timeZoneNames from "../../../node_modules/cldr-data/main/ko/timeZoneNames.json";
import * as NumberingSystems from "../../../node_modules/cldr-data/supplemental/numberingSystems.json";
import * as weekData from "../../../node_modules/cldr-data/supplemental/weekData.json";
import { ScheduleApi } from "../../system/api/scheduleApi";
import { ScheduleDetailInfo, ScheduleInfo } from "../../system/types/Board";
import { ErrorHandler } from "../../system/ApiService";
import { AlertModal } from "../../components/Common/AlertModal";
import { GRAYPALETITLE } from "../../styles/Color";
import {
  InitScheduleDetailInfo,
  InitScheduleInfo,
} from "../../system/types/initObject";
import { useRecoilValue } from "recoil";
import { AdminState } from "../../system/atoms";
import { isoDateFormatter } from "../../components/Common/isoDateFormatter";
import ScheduleDetail from "./ScheduleDetail";
import ScheduleEdit from "./ScheduleEdit";

loadCldr(NumberingSystems, gregorian, numbers, timeZoneNames, weekData);
L10n.load({
  ko: {
    schedule: {
      today: "오늘",
    },
  },
});

const getMonthName = (monthNumber: number) => {
  const monthNames = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  if (monthNumber >= 1 && monthNumber <= 12) {
    return monthNames[monthNumber - 1];
  } else {
    return "Invalid month number";
  }
};
function ScheduleMain() {
  const adminValue = useRecoilValue(AdminState);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [data, setData] = useState<ScheduleDetailInfo[]>([]);
  const [createData, setCreateData] = useState<ScheduleInfo>(InitScheduleInfo);
  const [selectedData, setSelectedData] = useState<ScheduleDetailInfo>(
    InitScheduleDetailInfo
  );
  // edit, detail
  const [modalType, setModalType] = useState("detail");
  // 모달
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setModalType("edit");
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedData(InitScheduleDetailInfo);
    setCreateData(InitScheduleInfo);
  };

  const refreshData = () => {
    if (selectedDate) {
      const monthName = getMonthName(selectedDate.getMonth() + 1);
      if (monthName !== "Invalid month number") {
        ScheduleApi.getSchedule(selectedDate.getFullYear(), monthName)
          .then((res) => {
            res.data && res.data["전체"] && setData(res.data["전체"]);
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          });
      }
    }
  };

  useEffect(() => {
    refreshData();
  }, [selectedDate]);

  let scheduleObj = useRef<ScheduleComponent | null>(null);
  const template = (props: any) => {
    if (
      props.IsAllDay === false &&
      isoDateFormatter(new Date(props.StartTime)) ===
        isoDateFormatter(new Date(props.EndTime))
    ) {
      return (
        <CustomText type="body" paddingLeft="5px" cursorPointer>
          {props.Subject} (
          {(new Date(props.StartTime).getHours() < 10
            ? "0" + new Date(props.StartTime).getHours()
            : new Date(props.StartTime).getHours()) +
            ":" +
            (new Date(props.StartTime).getMinutes() < 10
              ? "0" + new Date(props.StartTime).getMinutes()
              : new Date(props.StartTime).getMinutes()) +
            " ~ " +
            (new Date(props.EndTime).getHours() < 10
              ? "0" + new Date(props.EndTime).getHours()
              : new Date(props.EndTime).getHours()) +
            ":" +
            (new Date(props.EndTime).getMinutes() < 10
              ? "0" + new Date(props.EndTime).getMinutes()
              : new Date(props.EndTime).getMinutes())}
          )
        </CustomText>
      );
    } else {
      return (
        <CustomText type="body" paddingLeft="5px" cursorPointer>
          {props.Subject}
        </CustomText>
      );
    }
  };

  const onClickRez = (dataItem: any) => {
    setSelectedData(dataItem.Data);
    setOpen(true);
    setCreateData({
      subject: dataItem.Data.subject,
      contents: dataItem.Data.contents,
      categoryColor: dataItem.Data.categoryColor,
      startTime: dataItem.Data.startTime,
      endTime: dataItem.Data.endTime,
      isAllDay: dataItem.Data.isAllDay,
      isAllGroup: dataItem.Data.isAllGroup,
    });
  };

  function onEventRendered(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.CategoryColor as string;

    if (!args.element || !categoryColor) {
      return;
    } else {
      if (args.data.IsAllDay) {
        args.element.style.border = "0px";
        args.element.style.borderRadius = "5px";
        args.element.style.backgroundColor = categoryColor;
        args.element.style.color = "#ffffff";
        args.element.style.height = "21px";
        args.element.style.fontSize = "1.0rem";
        args.element.style.marginTop = "5px";
        args.element.style.cursor = "pointer";
      } else {
        args.element.style.border = `1px solid ${categoryColor}`;
        args.element.style.borderRadius = "5px";
        args.element.style.color = GRAYPALETITLE;
        args.element.style.backgroundColor = "#ffffff";
        args.element.style.height = "21px";
        args.element.style.fontSize = "1.0rem";
        args.element.style.fontWeight = "700";
        args.element.style.marginTop = "5px";
        args.element.style.cursor = "pointer";
      }
    }

    if (adminValue) {
      args.element.addEventListener("click", () => onClickRez(args.data));
      setModalType("edit");
    } else {
      args.element.addEventListener("click", () => onClickRez(args.data));
      setModalType("detail");
    }
  }
  function onDataBinding(e: any) {
    let items = e.result;
    let ScheduleData: any = [];
    if (items.length > 0) {
      for (var i = 0; i < items.length; i++) {
        ScheduleData.push({
          Id: items[i].id,
          Subject: items[i].subject,
          StartTime: new Date(items[i].startTime),
          EndTime: new Date(items[i].endTime),
          IsAllDay: items[i].isAllDay,
          CategoryColor: items[i].categoryColor,
          Data: items[i],
        });
      }
    }
    e.result = ScheduleData;
  }
  const onPopupOpen = (args: any) => {
    args.cancel = true;
  };
  const handleDateNavigate = (args: any) => {
    if (
      (args.requestType === "dateNavigate" || "toolBarItemRendered") &&
      scheduleObj.current?.selectedDate
    ) {
      setSelectedDate(scheduleObj.current?.selectedDate);
    }
  };

  return (
    <>
      <CustomText type="title">일정</CustomText>

      <Grid container item xs={12} justifyContent="center" mt="30px">
        {adminValue && (
          <Grid container justifyContent="right" item xs={12}>
            <CustomButtonGreen onClick={handleOpen}>
              일정 생성
            </CustomButtonGreen>
          </Grid>
        )}
        <Grid container item xs={12} mt="10px">
          <ScheduleComponent
            currentView="Month"
            locale="ko"
            rowAutoHeight={true}
            eventSettings={{
              dataSource: data,
              template: template as any,
            }}
            ref={scheduleObj}
            eventRendered={onEventRendered}
            readonly={true}
            dataBinding={onDataBinding}
            popupOpen={onPopupOpen}
            actionComplete={handleDateNavigate}
          >
            <ViewsDirective>
              <ViewDirective option="Month" />
            </ViewsDirective>
            <Inject services={[Month]} />
          </ScheduleComponent>
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={ModalStyle}>
            <Grid container spacing={2}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                item
                xs={12}
              >
                <CustomText type="title">
                  {modalType === "detail"
                    ? "일정 상세"
                    : selectedData?.id
                    ? "일정 수정"
                    : "일정 생성"}
                </CustomText>

                <PointerItem onClick={handleClose}>
                  <X size={32} />
                </PointerItem>
              </Grid>
              <Grid item xs={12}>
                {modalType === "detail" ? (
                  <ScheduleDetail selectedData={selectedData} />
                ) : (
                  <ScheduleEdit
                    createData={createData}
                    setCreateData={setCreateData}
                    handleClose={handleClose}
                    refreshData={refreshData}
                    editStatus={selectedData.id ? selectedData.id : null}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </>
  );
}

export default ScheduleMain;
