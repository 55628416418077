import { Box, Grid, styled } from "@mui/material";
import { Gray200 } from "../../../../styles/Color";
import {
  MediaStyleProperties,
  StyledDeviceTypeProps,
} from "../../../../types/Common/MediaType";
import { styledWithoutProps } from "../../../../styles/deviceTypeStyle";

// PortletList
export const PortletListWrapper = styled(Grid)({
  flexWrap: "wrap",
  marginTop: "20px",
  justifyContent: "space-between",
});

// PortletListItem
export const PortletItemWrapper = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  height: "auto",
  boxSizing: "border-box",
  border: `1px solid ${Gray200}`,
  borderRadius: "4px",
  margin: "4px 0",
  padding: "16px",
});

export const PortletItemTitle = styled(Box)({
  fontSize: "1.125rem",
  fontWeight: 600,
});

const PortletItemHeaderWrapperMarginBottom: MediaStyleProperties = {
  pc: "16px",
  tablet: "16px",
  mobile: "8px",
};
export const PortletItemHeaderWrapper = styledWithoutProps(Box)(
  ({ deviceType }: StyledDeviceTypeProps) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: PortletItemHeaderWrapperMarginBottom[deviceType],
  })
);

export const PortletItemContentWrapper = styled(Box)({
  display: "flex",
  flex: 1,
  width: "100%",
  justifyContent: "center",
  overflowY: "auto",
});
