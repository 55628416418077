import { CustomText } from "../../../styles/CustomText";
import {
  AdminGroupDivider,
  AdminGroupItemBox,
} from "../../../styles/adminStyle";
import AdminGroupInfo from "./components/AdminGroupInfo";
import AdminGroupMember from "./components/AdminGroupMember";
import { Grid } from "@mui/material";
import AdminGroupSetting from "./components/AdminGroupSetting";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { GroupApi } from "../../../system/api/groupApi";
import { GroupInfo } from "../../../system/types/Group";
import { ErrorHandler } from "../../../system/ApiService";
import { AlertModal } from "../../../components/Common/AlertModal";
import { InitGroupInfo } from "../../../system/types/initObject";
import { EmployeeInfo } from "../../../system/types/Employee";
import { EmployeeApi } from "../../../system/api/employeeApi";

function AdminGroupDetailPage() {
  const { id } = useParams<{ id: string }>();
  const [groupData, setGroupData] = useState<GroupInfo>(InitGroupInfo);
  const [employeeList, setEmployeeList] = useState<EmployeeInfo[]>([]);
  const refreshData = useCallback(() => {
    if (id) {
      GroupApi.getAllGroupInfo(Number(id))
        .then((res) => {
          setGroupData(res.data[0]);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [id]);

  useEffect(() => {
    refreshData();
    EmployeeApi.GetEmployee()
      .then((res) => {
        setEmployeeList(res.data);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        AlertModal("msg", msg);
      });
  }, [refreshData]);

  return (
    <>
      <CustomText type="title">설정 {id && "- " + groupData.name}</CustomText>
      <AdminGroupDivider container justifyContent="space-between">
        <Grid item xs={12} sm={5.9}>
          <AdminGroupItemBox container>
            <AdminGroupInfo
              groupData={groupData}
              refreshData={refreshData}
              employeeList={employeeList}
            />
          </AdminGroupItemBox>
          <AdminGroupItemBox container>
            <AdminGroupSetting
              groupData={groupData}
              refreshData={refreshData}
              employeeList={employeeList}
            />
          </AdminGroupItemBox>
        </Grid>
        <Grid item xs={12} sm={5.9}>
          <AdminGroupItemBox container>
            <AdminGroupMember id={Number(id)} employeeList={employeeList} />
          </AdminGroupItemBox>
        </Grid>
      </AdminGroupDivider>
    </>
  );
}
export default AdminGroupDetailPage;
