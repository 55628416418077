import { Box, Grid, Modal } from "@mui/material";
import {
  IntermediaryCompanyInfo,
  LeadCustomerDetailInfo,
  LeadOrdersInfo,
  QaQcInfo,
  SectionStatesInfo,
} from "../../../../../system/types/Customer";
import LeadBranch from "./LeadBranch";
import LeadDoc from "./LeadDoc";
import LeadEtc from "./LeadEtc";
import LeadInfo from "./LeadInfo";
import LeadProduct from "./LeadProduct";
import { useState } from "react";
import { isoDateFormatter } from "../../../../../components/Common/isoDateFormatter";
import { QaQcApi } from "../../../../../system/api/qaQcApi";
import { AlertModal } from "../../../../../components/Common/AlertModal";
import { ErrorHandler } from "../../../../../system/ApiService";
import {
  InitLeadOrdersInfo,
  InitQaQcInfo,
} from "../../../../../system/types/initObject";
import { InputBox, ModalStyle, PointerItem } from "../../../../../styles/theme";
import { CustomText } from "../../../../../styles/CustomText";
import { X } from "@phosphor-icons/react";
import { REDTITLE } from "../../../../../styles/Color";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { CustomButtonGreen } from "../../../../../styles/buttonStyle";
import LeadStep from "./LeadStep";
import {
  ContactTypeListInfo,
  DropdownListInfo,
  TagListInfo,
} from "../../../../../system/types/List";

interface LeadDetailMainProps {
  data: LeadCustomerDetailInfo | null;
  refreshData: () => void;
  productFamilyList: string[];
  sasToken: string;
  stepData: DropdownListInfo[];
  refreshActivityData: () => void;
  IndustryClassificationData: DropdownListInfo[];
  classTeamCenterData: DropdownListInfo[];
  intermediaryData: IntermediaryCompanyInfo[];
  saleTypeData: DropdownListInfo[];
  contactTypeData: ContactTypeListInfo[];
  contractTypeData: DropdownListInfo[];
  supplyTypeData: DropdownListInfo[];
  tagData: TagListInfo[];
}

function validation(data: QaQcInfo) {
  if (data.documentId === "") {
    AlertModal("check", "문서번호를 작성해 주세요.");
    return false;
  } else if (data.deliveryDate === "") {
    AlertModal("check", "납품일을 선택해 주세요.");
    return false;
  }
  return true;
}

function LeadDetailMain({
  data,
  refreshData,
  productFamilyList,
  sasToken,
  stepData,
  refreshActivityData,
  IndustryClassificationData,
  classTeamCenterData,
  intermediaryData,
  saleTypeData,
  contactTypeData,
  contractTypeData,
  supplyTypeData,
  tagData,
}: LeadDetailMainProps) {
  const [sectionStates, setSectionStates] = useState<SectionStatesInfo>({
    LeadInfo: true,
    LeadEtc: true,
    LeadBranch: true,
  });
  const toggleSection = (title: keyof SectionStatesInfo) => {
    setSectionStates({
      ...sectionStates,
      [title]: !sectionStates[title],
    });
  };

  const [open, setOpen] = useState(false);
  const [qaqcData, setQaQcData] = useState<QaQcInfo>(InitQaQcInfo);
  const handleClose = () => {
    setOpen(false);
  };
  const [selectedData, setSelectedData] =
    useState<LeadOrdersInfo>(InitLeadOrdersInfo);

  const convertListToString = (list: string[]) => {
    return list.join(", ");
  };

  const handleOpen = (item: LeadOrdersInfo) => {
    setOpen(true);
    setSelectedData(item);
    if (item.qaQc === null) {
      setQaQcData({ ...InitQaQcInfo, orderId: item.id ? item.id : null });
      setInputValue("");
    } else {
      setQaQcData({
        ...InitQaQcInfo,
        id: item.qaQc.id,
        orderId: item.id ? item.id : null,
        documentId: item.qaQc.documentId,
        deliveryDate: item.qaQc.deliveryDate,
      });
      setInputValue(convertListToString(item.qaQc.serialNumbers));
    }
  };

  var submitcheck = false;

  const onChange = (args: any) => {
    setQaQcData({
      ...qaqcData,
      [(args.target as HTMLInputElement).name]: args.target.value,
    });
  };

  const onChangeDate = (args: any) => {
    setQaQcData({
      ...qaqcData,
      [(args.target as HTMLInputElement).name]: isoDateFormatter(
        new Date(args.target.value)
      ),
    });
  };
  const onSave = () => {
    if (validation(qaqcData) && submitcheck === false) {
      submitcheck = true;
      if (qaqcData?.id) {
        QaQcApi.updateQaQc(qaqcData?.id, {
          ...qaqcData,
          serialNumbers: inputValue.split(/,\s*|\s+/).filter((item) => item),
        })
          .then(() => {
            handleClose();
            AlertModal("success", "조달검사검수 요청이 수정되었습니다.");
            refreshData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = true;
          });
      } else {
        QaQcApi.createQaQc({
          ...qaqcData,
          serialNumbers: inputValue.split(/,\s*|\s+/).filter((item) => item),
        })
          .then(() => {
            handleClose();
            AlertModal("success", "조달검사검수 요청이 완료되었습니다.");
            refreshData();
          })
          .catch((err) => {
            let msg = ErrorHandler(err);
            AlertModal("msg", msg);
          })
          .finally(() => {
            submitcheck = true;
          });
      }
    }
  };

  const [inputValue, setInputValue] = useState("");
  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };
  const handlePaste = (e: any) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    const formattedData = pasteData.split(/[\t\n]+/).join(", ");
    if (formattedData.endsWith(", ")) {
      const formattedEditData = formattedData.slice(0, -2);
      setInputValue(formattedEditData);
    } else {
      setInputValue(formattedData);
    }
  };
  const calculatedHeight =
    900 +
    ((data?.attachmentFiles ? data?.attachmentFiles.length : 0) +
      (data?.orders ? data?.orders.length : 0)) *
      57;
  return (
    <Grid container item xs={12}>
      <LeadStep
        data={data}
        stepData={stepData}
        refreshData={refreshData}
        refreshActivityData={refreshActivityData}
      />
      <LeadInfo
        data={data ? data.customer : null}
        refreshData={refreshData}
        sectionStates={sectionStates}
        toggleSection={toggleSection}
        IndustryClassificationData={IndustryClassificationData}
        classTeamCenterData={classTeamCenterData}
      />
      <LeadEtc
        data={data ? data.customer : null}
        refreshData={refreshData}
        sectionStates={sectionStates}
        toggleSection={toggleSection}
        intermediaryData={intermediaryData}
        saleTypeData={saleTypeData}
        contactTypeData={contactTypeData}
        contractTypeData={contractTypeData}
        supplyTypeData={supplyTypeData}
        tagData={tagData}
      />
      <LeadBranch
        data={data ? data.customer : null}
        refreshData={refreshData}
        sectionStates={sectionStates}
        toggleSection={toggleSection}
      />
      <LeadProduct
        data={data ? data?.orders : null}
        refreshData={refreshData}
        qaqcState={data?.customer?.contractType?.name === "조달" ? true : false}
        handleOpen={handleOpen}
        customerId={data?.customer.id ? data?.customer.id : null}
        productFamilyList={productFamilyList}
        sellerId={data?.customer.seller?.id ? data?.customer.seller?.id : null}
      />
      <LeadDoc
        data={data ? data?.attachmentFiles : null}
        refreshData={refreshData}
        customerId={data?.customer.id ? data?.customer.id : null}
        sasToken={sasToken}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title">
                {qaqcData?.id ? "조달검사검수 요청 수정" : "조달검사검수 요청"}
              </CustomText>
              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12}>
                <CustomText type="subtitle">장비명</CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                {selectedData.product.name}
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <CustomText type="subtitle">문서번호</CustomText>
                <CustomText type="small" color={REDTITLE}>
                  *
                </CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                <InputBox
                  type="text"
                  name="documentId"
                  placeholder="문서번호를 입력하세요."
                  autoComplete="new-password"
                  onChange={onChange}
                  value={qaqcData.documentId}
                />
              </Grid>
              <Grid container item xs={12} alignItems="center">
                <CustomText type="subtitle">납품일</CustomText>
                <CustomText type="small" color={REDTITLE}>
                  *
                </CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                <DatePickerComponent
                  name="deliveryDate"
                  placeholder="납품일을 선택하세요."
                  format="yyyy-MM-dd"
                  locale="ko"
                  value={
                    qaqcData.deliveryDate
                      ? new Date(qaqcData.deliveryDate)
                      : undefined
                  }
                  onChange={onChangeDate}
                  showClearButton={false}
                  max={new Date()}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomText type="subtitle">
                  시리얼번호 (
                  {inputValue.split(/,\s*|\s+/).filter((item) => item).length}/
                  {selectedData.quantity}개 작성)
                </CustomText>
              </Grid>
              <Grid item xs={12}>
                <CustomText type="subtitle" bold400>
                  엑셀 내용을 붙여넣기하시거나 ,(콤마)로 구분해서 작성해주세요.
                </CustomText>
              </Grid>
              <Grid item xs={12} mb="5px">
                <InputBox
                  type="text"
                  name="serialNumbers"
                  placeholder="시리얼 번호를 입력하세요."
                  autoComplete="new-password"
                  value={inputValue}
                  onChange={handleChange}
                  onPaste={handlePaste}
                />
              </Grid>
              <Grid container item xs={12} justifyContent="right">
                <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Grid>
  );
}

export default LeadDetailMain;
