import { Grid } from "@mui/material";
import {
  LeadCustomerTableInfo,
  LeadMyTableInfo,
} from "../../../../system/types/Customer";
import {
  MobileTableContentBox,
  MobileTableContentDetailBox,
  MobileTableContentsBox,
  MobileTableTitleBox,
} from "../../../../styles/theme";
import { SortAscending } from "@phosphor-icons/react";
import { GRAYPALEDISABLED } from "../../../../styles/Color";
import { useRecoilValue } from "recoil";
import { AdminState } from "../../../../system/atoms";
import uuid from "react-uuid";
import { useState } from "react";
import { CustomButtonGreen } from "../../../../styles/buttonStyle";
import { useHistory } from "react-router-dom";
import { isoDateFormatter } from "../../../../components/Common/isoDateFormatter";
interface LeadTableListMobileProps {
  data: LeadCustomerTableInfo[] | LeadMyTableInfo[];
  type: string;
}

function LeadTableListMobile({ data, type }: LeadTableListMobileProps) {
  const history = useHistory();
  const adminValue = useRecoilValue(AdminState);
  const [detailInfoId, setDetailInfoId] = useState(0);
  const [infoOpen, setInfoOpen] = useState(false);
  function onChangeDetailInfoId(id: number, index: number) {
    if (infoOpen && index !== detailInfoId) {
      setDetailInfoId(index);
    } else {
      setInfoOpen(!infoOpen);
      setDetailInfoId(index);
    }
  }

  function onMove(id: number) {
    history.push(`/list/detail/${id}`);
  }

  return (
    <Grid style={{ marginTop: "10px" }}>
      <MobileTableTitleBox container alignItems="center">
        <SortAscending size={32} style={{ color: GRAYPALEDISABLED }} />
      </MobileTableTitleBox>
      <Grid>
        {data.map(
          (dt: LeadCustomerTableInfo | LeadMyTableInfo, index: number) => (
            <Grid key={uuid()}>
              <MobileTableContentsBox
                container
                onClick={() => onChangeDetailInfoId(dt.id, index)}
              >
                <MobileTableContentBox item xs={4.5}>
                  {dt.groupName}
                </MobileTableContentBox>
                <MobileTableContentBox item xs={4.5}>
                  {dt.placeName}
                </MobileTableContentBox>
                <MobileTableContentBox item xs={3}>
                  {dt.productFamily}
                </MobileTableContentBox>
              </MobileTableContentsBox>
              {infoOpen && detailInfoId === index && (
                <Grid container>
                  <MobileTableContentDetailBox item xs={4}>
                    지사
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={8}>
                    {dt.groupName}
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={4}>
                    상호
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={8}>
                    {dt.placeName}
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={4}>
                    주소
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={8}>
                    {dt.address}
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={4}>
                    제품군
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={8}>
                    {dt.productFamily}
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={4}>
                    모델명
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={8}>
                    {dt.productName}
                  </MobileTableContentDetailBox>

                  {dt?.businessType ? (
                    <>
                      {" "}
                      <MobileTableContentDetailBox item xs={4}>
                        업종
                      </MobileTableContentDetailBox>
                      <MobileTableContentDetailBox item xs={8}>
                        {dt.businessType}
                      </MobileTableContentDetailBox>
                    </>
                  ) : (
                    ""
                  )}
                  {dt?.saleType ? (
                    <>
                      <MobileTableContentDetailBox item xs={4}>
                        판매방식
                      </MobileTableContentDetailBox>
                      <MobileTableContentDetailBox item xs={8}>
                        {dt.saleType}
                      </MobileTableContentDetailBox>
                    </>
                  ) : (
                    ""
                  )}

                  <MobileTableContentDetailBox item xs={4}>
                    영업방식
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={8}>
                    [{dt.contactTypeMajor}] {dt.contactTypeSub}
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={4}>
                    영업 담당자
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={8}>
                    {dt.sellerName}
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={4}>
                    구매 예상 시기
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={8}>
                    {dt.estimatedDate}
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={4}>
                    미팅노트 작성일
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={8}>
                    {dt.firstMeetingDate &&
                      isoDateFormatter(new Date(dt.firstMeetingDate))}
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={4}>
                    문서 작성일
                  </MobileTableContentDetailBox>
                  <MobileTableContentDetailBox item xs={8}>
                    {dt.createdAt && isoDateFormatter(new Date(dt.createdAt))}
                  </MobileTableContentDetailBox>
                  {(adminValue || type === "my") && (
                    <MobileTableContentDetailBox item xs={12}>
                      <CustomButtonGreen onClick={() => onMove(dt.id)}>
                        자세히 보기
                      </CustomButtonGreen>
                    </MobileTableContentDetailBox>
                  )}
                </Grid>
              )}
            </Grid>
          )
        )}
        {data.length === 0 && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            margin="20px 0px"
          >
            조회된 데이터가 없습니다.
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default LeadTableListMobile;
