import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemText, ListItemButton } from "@mui/material";

import { Link, useLocation } from "react-router-dom";

import { MenuList, menuList } from "../../../route/Urls";
import { CustomText } from "../../../styles/CustomText";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AdminState,
  BoardPageNumber,
  ListTypeState,
  ManagerState,
  SearchDataAtom,
  leftDrawerOpenState,
  registerLeadStateAtom,
} from "../../../system/atoms";
import { InitListSearchInfo } from "../../../system/types/initObject";

type DrawerMenuListProps = {
  type: string;
};

function DrawerMenuList({ type }: DrawerMenuListProps) {
  const location = useLocation();

  const [openSections, setOpenSections] = useState<Record<string, boolean>>(
    menuList.reduce((acc, item) => {
      acc[item.key] = true;
      return acc;
    }, {} as Record<string, boolean>)
  );

  const managerValue = useRecoilValue(ManagerState);
  const adminValue = useRecoilValue(AdminState);
  const [, setOpen] = useRecoilState(leftDrawerOpenState);
  const [, setPageNumber] = useRecoilState(BoardPageNumber);
  const [, setSearchDt] = useRecoilState(SearchDataAtom);
  const [, setListType] = useRecoilState(ListTypeState);
  const [, setRegisterLeadState] = useRecoilState(registerLeadStateAtom);
  const toggleSection = (key: string) => {
    setOpenSections((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const onChangePageNumber = () => {
    setPageNumber(1);
    setSearchDt(InitListSearchInfo);
    setListType("list");
  };

  function CloseDrawer() {
    setOpen(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPageNumber(1);
    setSearchDt(InitListSearchInfo);
    setListType("list");
  }

  const onChangeRegisterLeadState = () => {
    setRegisterLeadState("");
  };

  const renderMenuList = (list: MenuList) => {
    return list.map((item) => {
      // 조건에 따라 메뉴 표시 여부 결정
      if (item.requiresAdmin && !adminValue && !managerValue) {
        return null;
      }

      if (item.children) {
        return (
          <div key={item.key}>
            <ListItemButton
              sx={{
                marginBottom: !openSections[item.key] ? "4px" : undefined,
                paddingRight: "28px",
                ...mainItemStyle,
                display: "flex",
                justifyContent: "space-between", // 양쪽 끝으로 정렬
                alignItems: "center",
              }}
              onClick={() => toggleSection(item.key)}
            >
              <ItemTextWrapper>
                <CustomText
                  type="subtitle"
                  cursorPointer
                  bold400
                  paddingLeft={"6px"}
                >
                  {item.label}
                </CustomText>
              </ItemTextWrapper>
              {openSections[item.key] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
              sx={{ ...collapseStyle }}
              in={openSections[item.key]}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
                onClick={
                  item.key === "register"
                    ? onChangeRegisterLeadState
                    : undefined
                }
              >
                {item.children.map((child) => {
                  if (child.requiresAdmin && !adminValue && !managerValue) {
                    return null;
                  }

                  if (child.external && child.toUrl) {
                    return (
                      <ListItemButton
                        key={child.key}
                        sx={{ paddingLeft: "36px", ...collapseItemStyle }}
                        onClick={() => window.open(child.toUrl, "_blank")}
                      >
                        <ListItemText>
                          <CustomText type="body" cursorPointer>
                            {child.label}
                          </CustomText>
                        </ListItemText>
                      </ListItemButton>
                    );
                  }

                  if (child.toUrl) {
                    return (
                      <ListItemButton
                        key={child.key}
                        selected={location.pathname === child.toUrl}
                        component={Link}
                        to={child.toUrl}
                        sx={{ paddingLeft: "36px", ...collapseItemStyle }}
                        onClick={
                          type === "pc" ? onChangePageNumber : CloseDrawer
                        }
                      >
                        <ListItemText>
                          <CustomText type="body" cursorPointer>
                            {child.label}
                          </CustomText>
                        </ListItemText>
                      </ListItemButton>
                    );
                  }
                  return null;
                })}
              </List>
            </Collapse>
          </div>
        );
      }

      return null;
    });
  };

  return <>{renderMenuList(menuList)}</>;
}

export default DrawerMenuList;

interface ItemTextWrapperProps {
  children: React.ReactNode;
}

const ItemTextWrapper: React.FC<ItemTextWrapperProps> = ({ children }) => (
  <div>{children}</div>
);
const collapseStyle = {
  color: "rgb(221, 221, 221)",
  backgroundColor: "rgb(84, 84, 83)",
};

const collapseItemStyle = {
  "&&:hover": {
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(84, 84, 83)",
    filter: "brightness(1.2)",
  },
  "&&.Mui-selected": {
    backgroundColor: "rgb(84, 84, 83)",
    filter: "brightness(1.2)",
  },
};

const mainItemStyle = {
  height: "45px",
  "&&.Mui-selected": {
    backgroundColor: "rgb(84, 84, 83, 0.05)",
  },
  "&&.Mui-selected:hover": {
    backgroundColor: "rgb(84, 84, 83, 0.05)",
  },
};
