import {
  DahsboardHeaderTitle,
  DahsboardHeaderWrapper,
} from "../styles/DashboardHeaderStyle";

const DashboardHeader = () => {
  return (
    <DahsboardHeaderWrapper>
      <DahsboardHeaderTitle>조준형 님 반갑습니다.</DahsboardHeaderTitle>
    </DahsboardHeaderWrapper>
  );
};

export default DashboardHeader;
