import { Box, Grid, Modal } from "@mui/material";
import {
  AdminGroupItemBoxContentsPadding,
  AdminGroupItemBoxTitle,
} from "../../../../styles/adminStyle";
import { CustomText } from "../../../../styles/CustomText";
import Chip from "@mui/material/Chip";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  CustomButtonGreen,
  CustomButtonSmallCommon,
  CustomButtonSmallRed,
} from "../../../../styles/buttonStyle";
import { useCallback, useEffect, useState } from "react";
import { GroupApi } from "../../../../system/api/groupApi";
import { EmployeeInfo } from "../../../../system/types/Employee";
import { ErrorHandler } from "../../../../system/ApiService";
import { AlertModal } from "../../../../components/Common/AlertModal";
import uuid from "react-uuid";
import Swal from "sweetalert2";
import { GREENCONTENTS, REDTITLE } from "../../../../styles/Color";
import { ModalStyle, PointerItem } from "../../../../styles/theme";
import { X } from "@phosphor-icons/react";
import SelectMultiEmployee from "../../../../components/Common/SelectMultiEmployee";
import { InitGroupEmployeeInfo } from "../../../../system/types/initObject";
import { GroupEmployeeInfo } from "../../../../system/types/Group";
interface AdminGroupMemberProps {
  id: number;
  employeeList: EmployeeInfo[];
}

function AdminGroupMember({ id, employeeList }: AdminGroupMemberProps) {
  const [groupMemberData, setGroupMemberData] = useState<EmployeeInfo[]>([]);
  const [addGroupMember, setAddGroupMember] = useState<GroupEmployeeInfo>({
    ...InitGroupEmployeeInfo,
    groupId: id,
  });
  var submitcheck = false;
  const refreshGroupMember = useCallback(() => {
    if (id > 0) {
      GroupApi.findGroupEmployee(id)
        .then((res) => {
          setGroupMemberData(res.data);
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        });
    }
  }, [id]);

  useEffect(() => {
    refreshGroupMember();
  }, [refreshGroupMember]);

  const deleteGroupMember = (member: EmployeeInfo) => {
    if (submitcheck === false) {
      submitcheck = true;
      var deleteMemberList = { groupId: id, employees: [member] };

      Swal.fire({
        title: "정말 삭제하시겠습니까?",
        text: "선택하신 구성원이 삭제됩니다.",
        icon: "question",
        confirmButtonText: "예",
        cancelButtonText: "아니오",
        showCancelButton: true,
        confirmButtonColor: GREENCONTENTS,
        cancelButtonColor: REDTITLE,
      }).then((result) => {
        if (result.isConfirmed) {
          GroupApi.deleteGroupEmployee(deleteMemberList)
            .then(() => {
              AlertModal("success", "선택하신 구성원이 삭제되었습니다.");
              refreshGroupMember();
            })
            .catch((err) => {
              let msg = ErrorHandler(err);
              AlertModal("msg", msg);
            })
            .finally(() => {
              submitcheck = false;
            });
        }
      });
    }
  };
  // 모달
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const openAddModal = () => {
    setOpen(true);
    setAddGroupMember({ ...InitGroupEmployeeInfo, groupId: id });
  };
  const onChange = (memberList: EmployeeInfo[]) => {
    setAddGroupMember({ ...addGroupMember, employees: memberList });
  };
  const onSave = () => {
    if (submitcheck === false) {
      submitcheck = true;
      GroupApi.createGroupEmployee(addGroupMember)
        .then(() => {
          handleClose();
          refreshGroupMember();
        })
        .catch((err) => {
          let msg = ErrorHandler(err);
          AlertModal("msg", msg);
        })
        .finally(() => {
          submitcheck = false;
        });
    }
  };

  return (
    <>
      {" "}
      <AdminGroupItemBoxTitle
        paddingnumber="6px 10px"
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item xs={6}>
          <CustomText type="subtitle" bold400 mr="10px" color="#3D4861">
            구성원
          </CustomText>
          <Chip
            icon={<PersonOutlineIcon />}
            size="small"
            label={groupMemberData.length}
            variant="outlined"
          />
        </Grid>
        <Grid container item justifyContent="right" xs={6}>
          <CustomButtonSmallCommon onClick={openAddModal}>
            추가
          </CustomButtonSmallCommon>
        </Grid>
      </AdminGroupItemBoxTitle>
      {groupMemberData.length > 0 ? (
        <>
          {groupMemberData.map((member) => {
            return (
              <Grid item xs={12} key={uuid()}>
                <AdminGroupItemBoxContentsPadding
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <CustomText type="subtitle" bold500 color="#3D4861">
                    {member.name}
                  </CustomText>
                  <CustomButtonSmallRed
                    onClick={() => {
                      deleteGroupMember(member);
                    }}
                  >
                    삭제
                  </CustomButtonSmallRed>
                </AdminGroupItemBoxContentsPadding>
              </Grid>
            );
          })}
        </>
      ) : (
        <Grid container justifyContent="center" margin="10px">
          <CustomText type="subtitle" textAlign="center" color="#3D4861">
            구성원을 추가해주세요.
          </CustomText>
        </Grid>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Grid container spacing={2}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              xs={12}
            >
              <CustomText type="title"> 구성원 추가</CustomText>

              <PointerItem onClick={handleClose}>
                <X size={32} />
              </PointerItem>
            </Grid>
            <Grid item xs={12} mt={9} mb={6}>
              <SelectMultiEmployee
                list={addGroupMember.employees}
                onChange={onChange}
                employeeList={employeeList}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent="right" mt="20px">
            <CustomButtonGreen onClick={onSave}>저장</CustomButtonGreen>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default AdminGroupMember;
