import { Grid } from "@mui/material";
import uuid from "react-uuid";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  ProductInfo,
  ProductStatisticsDataInfo,
  ProductStatisticsxyInfo,
} from "../../../system/types/Statistics";
import {
  TableMainContentBox,
  TableMainNumberContentBox,
  TableMainTitleBox,
  TableOverflow,
  TableSubContentBox,
} from "../../../styles/statisticsTableStyle";
import { CustomText } from "../../../styles/CustomText";
interface StatusByProductTableProps {
  allProductData: any;
  productFamilyData: any;
  groupName: string[];
}

export default function StatusByProductTable({
  allProductData,
  productFamilyData,
  groupName,
}: StatusByProductTableProps) {
  const [selectedProductList, setSelectedProductList] = useState<string[]>([]);

  const onChangeProduct = (productFamilyName: string) => {
    const newBranchList = [...selectedProductList];
    const indexToRemove = newBranchList.indexOf(productFamilyName);
    if (indexToRemove !== -1) {
      newBranchList.splice(indexToRemove, 1);
    } else {
      newBranchList.push(productFamilyName);
    }
    setSelectedProductList(newBranchList);
  };

  return (
    <>
      <TableMainTitleBox container alignItems="center">
        <Grid item xs={0.5}></Grid>
        <Grid item xs={11.5 / (groupName.length + 1)}>
          <CustomText type="body">구분</CustomText>
        </Grid>
        {groupName.map((groupdt: string) => (
          <TableOverflow
            item
            xs={11.5 / (groupName.length + 1)}
            key={uuid()}
            container
            alignItems="center"
            justifyContent="right"
            pr="5px"
          >
            <CustomText type="body">{groupdt}</CustomText>
          </TableOverflow>
        ))}
      </TableMainTitleBox>

      {productFamilyData?.map(
        (item: ProductStatisticsDataInfo, index: number) => (
          <Grid container key={uuid()} alignItems="center">
            <TableMainContentBox
              container
              onClick={() => {
                onChangeProduct(item.productName);
              }}
              style={{ cursor: "pointer" }}
            >
              <Grid container justifyContent="center" item xs={0.5}>
                {selectedProductList.includes(item.productName) === true ? (
                  <ArrowDropUpIcon />
                ) : (
                  <ArrowDropDownIcon />
                )}
              </Grid>
              <TableOverflow
                item
                xs={11.5 / (groupName.length + 1)}
                container
                alignItems="center"
              >
                <CustomText type="body"> {item.productName}</CustomText>
              </TableOverflow>
              {item.data.map((xydata: ProductStatisticsxyInfo) => (
                <TableMainNumberContentBox
                  item
                  xs={11.5 / (groupName.length + 1)}
                  key={uuid()}
                >
                  <CustomText type="body"> {xydata.y}</CustomText>
                </TableMainNumberContentBox>
              ))}
            </TableMainContentBox>
            {selectedProductList.includes(item.productName) === true &&
              allProductData &&
              allProductData[item.productName] &&
              Object.entries(allProductData[item.productName])
                .map(([name, dataList]: [string, any]) => ({
                  name,
                  data: dataList.map((item: ProductInfo) => item.total),
                }))
                .map((productDT: { name: string; data: number[] }) => (
                  <Grid container key={uuid()}>
                    <TableSubContentBox container>
                      <Grid item xs={0.5}></Grid>
                      <TableOverflow item xs={11.5 / (groupName.length + 1)}>
                        <CustomText type="body" paddingLeft="10px">
                          {productDT.name}
                        </CustomText>
                      </TableOverflow>
                      {productDT.data.map((productNum: number) => (
                        <TableMainNumberContentBox
                          item
                          xs={11.5 / (groupName.length + 1)}
                          key={uuid()}
                        >
                          <CustomText type="body">{productNum}</CustomText>
                        </TableMainNumberContentBox>
                      ))}
                    </TableSubContentBox>
                  </Grid>
                ))}
          </Grid>
        )
      )}
    </>
  );
}
